import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ProductInterface} from "../models/product.interface";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ConstantsService} from "./constants.service";
import {ProductCategoryInterface} from "../models/product-category.interface";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  productList: BehaviorSubject<ProductInterface[]> = new BehaviorSubject<ProductInterface[]>([]);
  categoryList: BehaviorSubject<ProductCategoryInterface[]> = new BehaviorSubject<ProductCategoryInterface[]>([]);
  activeFilter: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

  constructor(
    private constantsService: ConstantsService,
    private http: HttpClient
  ) {}

  loadProductList() {
    if (!environment.production) {
      console.log('loading product list triggered');
    }
    this.http.get<ProductInterface[]>(`${this.constantsService.getApiEndpoint()}/shop/product-list/`, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.productList.next(result);
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading product list', error);
          setTimeout(() => this.loadProductList(), 1000);
        }
      }
    });
  }

  loadCategoryList() {
    if (!environment.production) {
      console.log('loading category list triggered');
    }
    this.http.get<ProductCategoryInterface[]>(`${this.constantsService.getApiEndpoint()}/shop/category-list/`, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.categoryList.next(result);
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading category list', error);
          setTimeout(() => this.loadCategoryList(), 1000);
        }
      }
    });
  }

  getProductsToView() {
    const products = this.productList.value.filter(p => p.is_active)
    if (this.activeFilter.value.length === 0) {
      return products;
    }
    return products.filter(p => p.product_category.some(c => this.activeFilter.value.includes(c)))
  }

  setActiveFilter(categories: number[]) {
    this.activeFilter.next(categories)
  }

  getImageUrl(id: number) {
    return `${this.constantsService.getApiEndpoint()}/shop/product-image/${id}/`
  }

  getFilterNames() {
    let name = '';
    this.activeFilter.value.forEach((aF, index) => {
      const category = this.categoryList.value.find(c => c.id === aF)
      if (index > 0) {
        name = `${name} und `;
      }
      if (category) {
        name = `${name}${category.category_name}`;
      }
    }
    )
    return name
  }

  getCategoryNames(categories: number[]) {
    let name = '';
    categories.forEach((aF, index) => {
      const category = this.categoryList.value.find(c => c.id === aF)
      if (index > 0) {
        name = `${name} und `;
      }
      if (category) {
        name = `${name}${category.category_name}`;
      }
    }
    )
    return name
  }

  getSingleProduct(id: String) {
    return this.productList.value.find(p => p.id === +id);
  }

  init() {
    this.loadCategoryList();
    this.loadProductList();
  }

 showSizeTable(type: number) {
    const filenames = ['Groessentabelle_Engelbert_Strauss_Hosen_Herren.pdf', 'Groessentabelle_Engelbert_Strauss_Oberbekleidung_Herren.pdf']
    window.open("assets/documents/" + filenames[type]);
  }
}
