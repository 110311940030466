import { Routes } from '@angular/router';
import {authGuard, authGuardManagement} from "./auth.guard";
import {HomeComponent} from "./shop/home/home.component";
import {LoginscreenComponent} from "./login/loginscreen/loginscreen.component";
import {CategoryViewComponent} from './shop/category-view/category-view.component';
import {ProductListViewComponent} from './shop/product-list-view/product-list-view.component';
import {SingleItemFullViewComponent} from './shop/single-item-full-view/single-item-full-view.component';
import {
  ShoppingCartFullViewComponent
} from './shop/shopping-cart/shopping-cart-full-view/shopping-cart-full-view.component';
import {SetNewPasswordComponent} from './tools/set-new-password/set-new-password.component';
import {RequestNewPasswordComponent} from './tools/request-new-password/request-new-password.component';
import {ProfileComponent} from './shop/profile/profile.component';
import {ManagementMainComponent} from './shop/management/management-main/management-main.component';
import {ProductManagementComponent} from './shop/management/product-management/product-management.component';
import {UserShoppingCartsComponent} from './shop/management/user-shopping-carts/user-shopping-carts.component';
import {SingleUserViewComponent} from './shop/management/single-user-view/single-user-view.component';
import {OrderListComponent} from './shop/management/order-list/order-list.component';
import {EditProductComponent} from './shop/management/product-management/edit-product/edit-product.component';
import {GeneralManagementComponent} from './shop/management/general-management/general-management.component';

export const routes: Routes = [
  { path: '', redirectTo: '/shop', pathMatch: 'full' },
  { path: 'login', component: LoginscreenComponent },
  { path: 'password-reset/:token', component: SetNewPasswordComponent, canActivate: [] },
  { path: 'request-new-password', component: RequestNewPasswordComponent, canActivate: [] },
  { path: 'shop', component: HomeComponent, canActivate: [authGuard] },
  { path: 'category', component: CategoryViewComponent, canActivate: [authGuard] },
  { path: 'products-list', component: ProductListViewComponent, canActivate: [authGuard] },
  { path: 'shopping-cart', component: ShoppingCartFullViewComponent, canActivate: [authGuard] },
  { path: 'product-view/:id', component: SingleItemFullViewComponent, canActivate: [authGuard] },
  { path: 'profile/:check', component: ProfileComponent, canActivate: [authGuard] },
  { path: 'management', component: ManagementMainComponent, canActivate: [authGuardManagement], children: [
      {
        path: '', redirectTo: 'user-management', pathMatch: 'full'
      },
      {
        path: 'user-management', component: UserShoppingCartsComponent,
      },
      {
        path: 'general-management', component: GeneralManagementComponent,
      },
      {
        path: 'order-list', component: OrderListComponent,
      },
      {
        path: 'user-management/:id/:mode', component: SingleUserViewComponent
      },
      {
        path: 'product-management', component: ProductManagementComponent
      },
      {
        path: 'product-management/:id/:mode', component: EditProductComponent
      },
    ]},
  { path: '**', redirectTo: 'shop' },
];
