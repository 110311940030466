import {Component, OnInit} from '@angular/core';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {DatePipe, Location, NgClass, NgForOf, NgIf} from '@angular/common';
import {faCheck, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {ConstantsService} from '../../../services/constants.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ShoppingCartInterface} from '../../../models/shopping-cart.interface';
import {environment} from '../../../../environments/environment';
import {ShopUserInterface} from '../../../models/shop-user.interface';
import {
  ShoppingCartItemViewComponent
} from '../../shopping-cart/shopping-cart-item-view/shopping-cart-item-view.component';
import {ShoppingCartService} from '../../../services/shopping-cart.service';

@Component({
  selector: 'app-single-user-view',
  standalone: true,
  imports: [
    FaIconComponent,
    NgForOf,
    NgIf,
    ShoppingCartItemViewComponent,
    DatePipe,
    NgClass
  ],
  templateUrl: './single-user-view.component.html',
  styleUrl: './single-user-view.component.css'
})
export class SingleUserViewComponent implements OnInit{
  user: ShopUserInterface | undefined;
  shoppingCart: ShoppingCartInterface | undefined;
  shoppingCartSelected: number | undefined;
  shoppingCartList: ShoppingCartInterface[] = [];
  constructor(private constantsService: ConstantsService,
              private http: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private shoppingCartService: ShoppingCartService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const userID = params.get('id');
      if (userID) {
        this.loadUser(userID);
        this.loadShoppingCart(userID);
        setTimeout(() => { this.loadShoppingCartList(userID);}, 700);
      }
    })
  }

  loadUser(id: string) {
    const body = {
      user_id: id
    };
    this.http.post<ShopUserInterface>(`${this.constantsService.getApiEndpoint()}/shop/user/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.user = result
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading user-data', error);
        }
      }
    });
  }

  showCart(id: number) {
    this.loadShoppingCartById(id.toString());
  }

  loadShoppingCartById(id: string) {
    const body = {
      cart_id: id
    };
    this.http.post<ShoppingCartInterface>(`${this.constantsService.getApiEndpoint()}/shop/get-shopping-cart-by-id/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.shoppingCart = result
        this.shoppingCartSelected = result.id;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading shoppingcart-by-id', error);
        }
      }
    });
  }

  loadShoppingCart(id: string) {
    const body = {
      shopuser_id: id
    };
    this.http.post<ShoppingCartInterface>(`${this.constantsService.getApiEndpoint()}/shop/get-shopping-cart-for-user/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.shoppingCart = result
        this.shoppingCartSelected = result.id;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading user-shoppingcart', error);
        }
      }
    });
  }

  loadShoppingCartList(id: string) {
    const body = {
      user_id: id
    };
    this.http.post<ShoppingCartInterface[]>(`${this.constantsService.getApiEndpoint()}/shop/get-shopping-cart-list-for-user/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.shoppingCartList = result
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading user-shoppingcart-list', error);
        }
      }
    });
  }

  getSum() {
    return this.shoppingCartService.getSum(this.shoppingCart!);
  }

  back() {
    this.location.back();
  }

  protected readonly faSpinner = faSpinner;
  protected readonly Object = Object;
  protected readonly checkIcon = faCheck;
}
