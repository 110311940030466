<div class="center">
  <h3 class="rail-theme">Willkommen</h3>
  <div class="welcome-message">
    <b>Willkommen zum Mitarbeiter‐Bestellsystem von railmen‐Berufsbekleidung.</b>
    <div>Dieser Bestellshop ist ein railmen-internes Organisationsmittel und hat keine direkte Lieferantenanbindung!
      <br><br>
      Während der laufenden Bestellperiode (Stichtag Bestellauslösung: 28./29. Februar + 31. August d. Jahres) können Sie im Rahmen Ihres Punkte‐Guthabens
      (300 Budgetpunkte pro Jahr) fortlaufend Produkte in Ihren persönlichen Warenkorb legen, entfernen oder ändern. Sobald die Bestellperiode abgelaufen ist,
      werden die zu diesem Zeitpunkt in Ihrem Warenkorb befindlichen Produkte durch die railmen‐Zentrale verbindlich
      für Sie bestellt. <br><br>
      Bitte achten Sie darauf, dass im Menüpunkt „Profil“ Ihre aktuelle Anschrift (entspricht der gewünschten Lieferanschrift) hinterlegt ist und diese bei Änderungen
      (z. B. durch Umzug) durch Sie entsprechend angepasst werden muss.
    </div>
    <br>
    <span><b>Größentabellen:</b><button (click)="openSizeTable(0)" class="btn btn-sm btn-link">Hosen</button><button
      (click)="openSizeTable(1)" class="btn btn-sm btn-link">Oberbekleidung</button></span>
  </div>
  <div class="button-section">
    <div class="b-div b-category" (click)="goTo('category')"><fa-icon class="icon" [icon]="shirtIcon"></fa-icon><div
      class="caption">Zu den
      Kategorien</div></div>
    <div
      class="b-div b-cart" (click)="goTo('shopping-cart')"><fa-icon class="icon" [icon]="cartIcon"></fa-icon><div
      class="caption">zum Warenkorb</div></div>
    <div
      class="b-div b-cart" (click)="goTo('profile/false')"><fa-icon class="icon" [icon]="profileIcon"></fa-icon><div
      class="caption">zum Profil</div></div>
  </div>
</div>
