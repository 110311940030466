import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-quantity-selector',
  standalone: true,
  imports: [CommonModule, FaIconComponent],
  templateUrl: './quantity-selector.component.html',
  styleUrl: './quantity-selector.component.css'
})
export class QuantitySelectorComponent implements OnInit{
  plusIcon = faPlus;
  minusIcon = faMinus;
  @Input() initial: number = 0
  @Input() min: number| undefined
  @Input() max: number | undefined
  @Input() steps: number = 1
  @Input() plusDisabled: boolean = false
  @Input() minusDisabled: boolean = false
  @Input() minusTitle: string = 'minus'
  @Input() plusTitle: string = 'plus'
  @Output() newQuantity = new EventEmitter<number>();
  number: number = 0;

  public constructor() {
  }

  ngOnInit() {
    this.number = this.initial;
  }


  increase() {
    this.number += 1;
    this.emitValue();
  }

  decrease() {
    this.number -= 1;
    this.emitValue();
  }

  emitValue() {
    this.newQuantity.emit(this.number);
  }

}
