<h3 class="rail-theme my-3 move-in">Nutzerverwaltung
  <button (click)="back()" class="btn btn-sm btn-primary mx-3">zurück</button>
</h3>
<h5>Benutzer</h5>
<div class="user-infos">
  <div>
    <div *ngIf="user != undefined" class="basic-card user">
      <div>
        <b>{{ user.user.first_name }} {{ user.user.last_name }}</b> <br>
        {{ user.user.email }}<br>
        {{ user.telephone_number }}
      </div>
      <div class="address">
        <b>Adresse</b><br>
        {{ user.street_address }} <br
        *ngIf="user.additional_address_information">{{ user.additional_address_information }}
        <br>{{ user.zip_code }} {{ user.town }}
      </div>
    </div>
  </div>
  <div *ngIf="user === undefined">
    <fa-icon [icon]="faSpinner"></fa-icon>
  </div>
</div>
<h5>Bestellungen</h5>
<div class="cartlist-wrapper my-2">
  <table class="table table-sm" *ngIf="shoppingCartList && shoppingCartList.length !== 0">
    <tr>
      <th>Erstellung</th>
      <th>letzte Änderung</th>
      <th>Punkte Beginn</th>
      <th>Punkte Ende</th>
      <th>Punkte Verbrauch</th>
      <th>Status</th>
      <th></th>
    </tr>
    <tr *ngFor="let sc of shoppingCartList" [ngClass]="{'selected': sc.id === shoppingCartSelected}">
      <td>{{ sc.created | date: 'dd.MM.y' }}</td>
      <td>{{ sc.updated | date: 'dd.MM.y' }}</td>
      <td>{{sc.credits_available}}</td>
      <td>{{sc.credits_remaining}}</td>
      <td>{{sc.credits_available - sc.credits_remaining}}</td>
      <td>
        <span *ngIf="!sc.archived">aktuell</span>
        <span *ngIf="sc.archived">archiviert</span>
      </td>
      <td>
        <button class="btn btn-sm btn-success" style="background-color: green;" (click)="showCart(sc.id)">auswählen</button>
      </td>
    </tr>
  </table>
  <div *ngIf="!shoppingCartList || shoppingCartList.length === 0">
    keine Bestellungen vorhanden
  </div>
</div>
<h5>Warenkorb <span *ngIf="shoppingCart !== undefined"> vom {{shoppingCart.created | date: 'dd.MM.y'}}
  <span *ngIf="shoppingCart.archived"> (archiviert)</span>
  <span *ngIf="!shoppingCart.archived"> (aktuell)</span>
</span></h5>
<div *ngIf="shoppingCart != undefined && shoppingCartSelected != undefined">
  <div *ngIf="Object.keys(shoppingCart.cart).length === 0">Der Warenkorb ist leer!</div>
  <div *ngIf="Object.keys(shoppingCart.cart).length > 0" class="cart">
    <app-shopping-cart-item-view *ngFor="let item of shoppingCart.cart"
                                 [shoppingCartProduct]="item" [managementMode]="true"></app-shopping-cart-item-view>
  </div>
</div>
<div *ngIf="shoppingCart === undefined">
  <fa-icon [icon]="faSpinner"></fa-icon>
</div>
