import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ShopUserInterface} from '../../models/shop-user.interface';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {DatePipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faCheck, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {animate, style, transition, trigger} from '@angular/animations';
import {
    ShoppingCartItemViewComponent
} from "../shopping-cart/shopping-cart-item-view/shopping-cart-item-view.component";
import {ShoppingCartInterface} from "../../models/shopping-cart.interface";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ConstantsService} from "../../services/constants.service";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    FaIconComponent,
    DatePipe,
    NgForOf,
    ShoppingCartItemViewComponent,
    NgClass
  ],
  animations:[
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0 }),
            animate('1s ease-out',
                    style({opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1 }),
            animate('1s ease-in',
                    style({opacity: 0 }))
          ]
        )
      ]
    )
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit, OnDestroy {
  shoppingCartSelected: number | undefined;
  shoppingCart: ShoppingCartInterface | undefined;
  shoppingCartList: ShoppingCartInterface[] = [];
  form: FormGroup;
  user: ShopUserInterface | null = null;
  userSub: Subscription;
  showCeckInfo = false;
  spinnerIcon = faSpinner;
  checkIcon = faCheck;
  loading = false;
  showUpdateBox = false;

  public constructor(private authService: AuthService,
                     private constantsService: ConstantsService,
                     private http: HttpClient,
                     private route: ActivatedRoute,
                     private router: Router) {
    this.form = new FormGroup({
      'firstName': new FormControl(''),
      'lastName': new FormControl(''),
      'email': new FormControl(''),
      'telephone': new FormControl(''),
      'street': new FormControl(''),
      'info': new FormControl(''),
      'postCode': new FormControl(''),
      'city': new FormControl(''),
    })
    this.userSub = this.authService.authUser.subscribe(
      (user) => {
        this.user = user;
        this.form.patchValue({
          'firstName': this.user?.user.first_name,
          'lastName': this.user?.user.last_name,
          'email': this.user?.user.email,
          'telephone': this.user?.telephone_number,
          'street': this.user?.street_address,
          'info': this.user?.additional_address_information,
          'postCode': this.user?.zip_code,
          'city': this.user?.town,
        });
        if (this.user) {
          this.loadShoppingCart(this.user.id!.toString());
          setTimeout(() => {
            if (this.user) {
              this.loadShoppingCartList(this.user.id!.toString());
            }}, 700);
        }
      }
    )
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const check = params.get('check');
      if (check === 'true') {
        this.showCeckInfo = true;
      }
    })
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  save() {
    this.loading = true;
    const body = {
      'zip_code': this.form.get('postCode')?.value,
      'street_address': this.form.get('street')?.value,
      'town': this.form.get('city')?.value,
      'additional_address_information': this.form.get('info')?.value,
      'telephone_number': this.form.get('telephone')?.value,
    }
    this.authService.updateUserInfo(body).subscribe({
      next: (result) => {
        this.authService.updateAuthUser(result);
        if (this.showCeckInfo) {
          this.router.navigate(['shop']);
        }
        this.loading = false;
        this.showUpdateBox = true;
        setTimeout(() => {
          this.showUpdateBox = false;
        }, 3000)
      },
      error: (error) => {
        console.log(error)
        this.loading = false;
      },
      complete: () => {
      }
    });
  }

  loadShoppingCartById(id: string) {
    const body = {
      cart_id: id
    };
    this.http.post<ShoppingCartInterface>(`${this.constantsService.getApiEndpoint()}/shop/get-shopping-cart-by-id/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.shoppingCart = result
        this.shoppingCartSelected = result.id;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading shoppingcart-by-id', error);
        }
      }
    });
  }

  loadShoppingCart(id: string) {
    const body = {
      shopuser_id: id
    };
    this.http.post<ShoppingCartInterface>(`${this.constantsService.getApiEndpoint()}/shop/get-shopping-cart-for-user/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.shoppingCart = result
        this.shoppingCartSelected = result.id;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading user-shoppingcart', error);
        }
      }
    });
  }

  loadShoppingCartList(id: string) {
    const body = {
      user_id: id
    };
    this.http.post<ShoppingCartInterface[]>(`${this.constantsService.getApiEndpoint()}/shop/get-shopping-cart-list-for-user/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.shoppingCartList = result
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading user-shoppingcart-list', error);
        }
      }
    });
  }

  showCart(id: number) {
    this.loadShoppingCartById(id.toString());
  }

  protected readonly faSpinner = faSpinner;
  protected readonly Object = Object;
}
