<h3 class="rail-theme my-3 move-in">Produkt {{ editMode? 'bearbeiten' : 'anlegen'}}
  <button (click)="back()"
          class="btn btn-sm btn-primary mx-3">{{ isEqual() ? 'zurück' : 'Änderungen verwerfen'}}</button>
  <button *ngIf="!isEqual()" (click)="save()" class="btn btn-sm btn-success my-1">Speichern</button>

</h3>
<div class="product-info management-content custom-scrollbar">
  <h4 class="rail-theme">Basisinfos</h4>
  <div class="info-wrapper main-grid">
    <div class="info">
      <label class="form-label">Aktiv</label>
      <select class="form-control" [(ngModel)]="product!.is_active" type="text">
        <option [value]="false">Nein</option>
        <option [value]="true">Ja</option>
      </select>
      <label class="form-label">Link</label>
      <input class="form-control" [(ngModel)]="product!.catalogue_link" type="text">
      <label class="form-label">Vertrieb</label>
      <select class="form-control" [(ngModel)]="product!.vendor_company" type="text">
        <option *ngFor="let v of vendorList" [value]="v.id">{{ v.vendor_company_name }}</option>
      </select>
      <label class="form-label">Kategorie</label>
      <select class="form-control" [(ngModel)]="product!.product_category[0]" type="text">
        <option *ngFor="let c of categoryList" [value]="c.id">{{ c.category_name }}</option>
      </select>
    </div>
    <div class="address">
      <label class="form-label">Name</label>
      <input class="form-control" [(ngModel)]="product!.product_name" type="text">
      <label class="form-label">Preis-Punkte</label>
      <input class="form-control" [(ngModel)]="product!.price_points" type="number">
      <label class="form-label">Veredler</label>
      <select class="form-control" [(ngModel)]="product!.branding_company" type="text">
        <option *ngFor="let b of brandingList" [value]="b.id">{{ b.branding_company_name }}</option>
      </select>
    </div>
  </div>
  <h4 class="rail-theme">Farbvarianten</h4>
  <button (click)="newColor()" class="btn btn-sm btn-primary"><fa-icon [icon]="faPlus"></fa-icon> neue Farbe
    anlegen</button>
  <div *ngFor="let c of product!.color_options" class="basic-card single-color" [ngClass]="{'deleted':
  colorsToDelete.includes(c.id!)}">
    <app-single-color (colorNOTDeleted)="colorNOTDeleteTriggered(c.id!)" (colorDeleted)="colorDeleteTriggered($event,
     c)"
                      [color]="c" (imageDeleted)="imageDeleteTriggered($event)"
                      (imageNOTDeleted)="imagesNOTDeleteTriggered($event)"
                      [deleteImages]="imagesToDelete"></app-single-color>
  </div>
  <div>
    <button (click)="back()"
          class="btn btn-sm btn-primary mx-3">{{ isEqual() ? 'zurück' : 'Änderungen verwerfen'}}</button>
    <button *ngIf="!isEqual()" (click)="save()" class="btn btn-sm btn-success my-1">Speichern</button>
  </div>
</div>

