import {Component, OnDestroy} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import {ProductsService} from '../../../services/products.service';
import {SingleItemSmallViewComponent} from '../../single-item-small-view/single-item-small-view.component';
import {ShoppingCartInterface} from '../../../models/shopping-cart.interface';
import {Subscription} from 'rxjs';
import {ShoppingCartItemViewComponent} from '../shopping-cart-item-view/shopping-cart-item-view.component';
import {AuthService} from '../../../services/auth.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-shopping-cart-full-view',
  standalone: true,
  imports: [CommonModule, SingleItemSmallViewComponent, ShoppingCartItemViewComponent, FaIconComponent],
  animations:[
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0 }),
            animate('1s ease-out',
                    style({opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1 }),
            animate('1s ease-in',
                    style({opacity: 0 }))
          ]
        )
      ]
    )
  ],
  templateUrl: './shopping-cart-full-view.component.html',
  styleUrl: './shopping-cart-full-view.component.css'
})
export class ShoppingCartFullViewComponent implements OnDestroy{
  shoppingCart: ShoppingCartInterface | null = null;
  showUpdateBox = false;
  checkIcon = faCheck;
  shoppingCartSub: Subscription;

  public constructor(private shoppingCartService: ShoppingCartService,
                     private productsService: ProductsService,
                     private authService: AuthService) {
    this.authService.checkIfAddressNeedsCheck()
    this.shoppingCartSub = this.shoppingCartService.shoppingCart.subscribe(
      (cart) => {
        this.shoppingCart = cart;
      }
    )
  }

  ngOnDestroy() {
    this.shoppingCartSub.unsubscribe();
  }


  getArticleCount() {
    return this.shoppingCartService.getArticleCount();
  }

  getProduct(id: number) {
    return this.productsService.getSingleProduct(String(id))
  }

  cartUpdated() {
    this.showUpdateBox = true;
    setTimeout(() => {
      this.showUpdateBox = false;
    }, 3000)
  }

  getSum() {
    return this.shoppingCartService.getSum(this.shoppingCart!);
  }

}
