import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductInterface} from '../../models/product.interface';
import {ProductsService} from '../../services/products.service';

@Component({
  selector: 'app-single-item-small-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './single-item-small-view.component.html',
  styleUrl: './single-item-small-view.component.css'
})
export class SingleItemSmallViewComponent {
  @Input() product!: ProductInterface;

  public constructor(private productService: ProductsService) {
  }

  getProductImage() {
    return this.productService.getImageUrl(this.product.base_images[0])
  }

}
