import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProductsService} from '../../../services/products.service';
import {ShoppingCartItemInterface} from '../../../models/shopping-cart.interface';
import {NgIf} from '@angular/common';
import {QuantitySelectorComponent} from '../../../tools/quantity-selector/quantity-selector.component';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {ShoppingCartService} from '../../../services/shopping-cart.service';

@Component({
  selector: 'app-shopping-cart-item-view',
  standalone: true,
  imports: [
    NgIf,
    QuantitySelectorComponent,
    FaIconComponent
  ],
  templateUrl: './shopping-cart-item-view.component.html',
  styleUrl: './shopping-cart-item-view.component.css'
})
export class ShoppingCartItemViewComponent {
  @Input() smallMode: boolean = false;
  @Input() managementMode: boolean = false;
  @Input() shoppingCartProduct: ShoppingCartItemInterface | undefined = undefined;
  @Output() cartUpdated = new EventEmitter();

  public constructor(private productsService: ProductsService,
                     private shoppingCartService: ShoppingCartService) {
  }
  trashItem = faTrash;

  getProductImage() {
    if (this.shoppingCartProduct?.p.pic) {
      return this.productsService.getImageUrl(this.shoppingCartProduct?.p.pic!);
    }
    return 'https://placehold.co/200x200?text=Bild+nicht+gefunden';
  }

  deleteItem() {
    this.shoppingCartService.deleteItem(this.shoppingCartProduct!.id);
    this.cartUpdated.emit();
  }

  updateQuantity(newQuantity: number) {
    if (newQuantity < this.shoppingCartProduct!.q) {
      this.shoppingCartService.reduceItemQuantity(this.shoppingCartProduct!.id)
    }
    if (newQuantity > this.shoppingCartProduct!.q) {
      this.shoppingCartService.addItemToCart(this.shoppingCartProduct!.p, this.shoppingCartProduct!.c, this.shoppingCartProduct!.s, 1);
    }
    this.cartUpdated.emit();
  }

  canAddItem() {
    return this.shoppingCartService.checkIfEnoughPointsAvailable(this.shoppingCartService.shoppingCart.value!, this.shoppingCartProduct!.p.p)
  }
}
