import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Location, NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {ProductsService} from '../../../../services/products.service';
import {
  BrandingInterface,
  ProductColorInterface,
  ProductInterface,
  VendorInterface
} from '../../../../models/product.interface';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {SingleColorComponent} from './single-color/single-color.component';
import {ProductCategoryInterface} from '../../../../models/product-category.interface';
import _ from 'lodash';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../../../environments/environment';
import {ConstantsService} from '../../../../services/constants.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-edit-product',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgForOf,
    FormsModule,
    NgOptimizedImage,
    FaIconComponent,
    SingleColorComponent,
    NgClass,
    NgIf,
  ],
  templateUrl: './edit-product.component.html',
  styleUrl: './edit-product.component.css'
})
export class EditProductComponent implements OnInit {
  loading = false;
  editMode: boolean = false;
  product: ProductInterface | undefined;
  inputProduct: ProductInterface | undefined;
  colorsToDelete: number[] = [];
  imagesToDelete: number[] = [];
  categoryList: ProductCategoryInterface[] = [];
  vendorList: VendorInterface[] = [];
  brandingList: BrandingInterface[] = [];


  constructor(private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private productsService: ProductsService,
              private constantsService: ConstantsService,
              private http: HttpClient,) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const productID = params.get('id');
      const mode = params.get('mode');
      this.editMode = mode === 'edit';
      if (this.editMode) {
        if (productID) {
          this.inputProduct = this.productsService.productList.value.find((p) => p.id!.toString() === productID)
          this.product = _.cloneDeep(this.inputProduct);
        }
      } else {
        this.product = {
          base_images: [],
          base_order_number: '',
          branding_company: 1,
          catalogue_link: '',
          color_options: [],
          colors_to_delete: [],
          description_text: '',
          is_active: true,
          price_points: 0,
          product_category: [],
          product_name: '',
          vendor_company: 1
        }
      }
    });
    this.categoryList = this.productsService.categoryList.value;
    this.vendorList = this.productsService.vendorList.value;
    this.brandingList = this.productsService.brandingList.value;
  }

  colorDeleteTriggered(edit: boolean, color: ProductColorInterface) {
    if (edit) {
      if (!this.colorsToDelete.includes(color.id!)) {
        this.colorsToDelete.push(color.id!);
      }
    } else {
      this.product!.color_options = this.product!.color_options.filter((c) => c !== color);
    }

  }

  colorNOTDeleteTriggered(id: number) {
    if (this.colorsToDelete.includes(id)) {
      this.colorsToDelete = this.colorsToDelete.filter((c) => c !== id);
    }
  }

  imageDeleteTriggered(id: number) {
    if (!this.imagesToDelete.includes(id)) {
      this.imagesToDelete.push(id);
    }
  }

  imagesNOTDeleteTriggered(id: number) {
    if (this.imagesToDelete.includes(id)) {
      this.imagesToDelete = this.imagesToDelete.filter((c) => c !== id);
    }
  }

  back() {
    this.location.back();
  }

  save() {
    console.log(this.product);
    this.loading = true;
    if (this.editMode) {
      this.updateProduct(this.product!);
    } else {
      this.createProduct(this.product!);
    }
    this.loading = false;
  }

  createProduct(product: ProductInterface) {
    const productData = {...product};

    productData.color_options = product.color_options?.map(colorOption => {
      const {new_images, delete_images, ...rest} = colorOption;
      return {
        ...rest,
        delete_images: delete_images || [], // Ensure it's an array or empty by default
      };
    });
    productData.colors_to_delete = this.colorsToDelete;

    this.http.post<ProductInterface>(
      `${this.constantsService.getApiEndpoint()}/shop/create-product-verwaltung/`,
      productData,
      {headers: this.constantsService.getHttpOptions()}
    ).subscribe({
      next: (updatedProduct) => {
        console.log(updatedProduct);
        var colorsToUpload = false;
        product.color_options?.filter((f) => f.new_images?.length && f.new_images.length > 0).forEach((colorOption, index) => {
          if (colorOption.new_images?.length) {
            const savedColor = updatedProduct.color_options.find((c) => c.color_name === colorOption.color_name);
            if (savedColor) {
              colorsToUpload = true;
              this.uploadImages(savedColor.id!, colorOption.new_images, index === (product.color_options?.filter((f) => f.new_images?.length && f.new_images.length > 0).length - 1));
            }
          }
        });
        if (!colorsToUpload) {
          this.product = updatedProduct;
          var products = this.productsService.productList.value;
          products.push(this.product);
          this.productsService.productList.next(products);
          this.productsService.categoryList.next(this.productsService.categoryList.value)
          this.router.navigate(['management/product-management']);
        }
      },
      error: (error) => {
        if (!environment.production) {
          console.log('Error updating product data', error);
        }
      }
    });
  }

  updateProduct(product: ProductInterface): void {
    const productData = {...product};

    productData.color_options = product.color_options?.map(colorOption => {
      const {new_images, delete_images, ...rest} = colorOption;
      return {
        ...rest,
        delete_images: delete_images || [], // Ensure it's an array or empty by default
      };
    });
    productData.colors_to_delete = this.colorsToDelete;
    console.log('colors_to_delete', productData.colors_to_delete)


    this.http.post<ProductInterface>(
      `${this.constantsService.getApiEndpoint()}/shop/update-product-verwaltung/`,
      productData,
      {headers: this.constantsService.getHttpOptions()}
    ).subscribe({
      next: (updatedProduct) => {
        console.log(updatedProduct);
        console.log(productData);
        var colorsToUpload = false;
        product.color_options?.filter((f) => f.new_images?.length && f.new_images.length > 0).forEach((colorOption, index) => {
          if (colorOption.new_images?.length) {
            const savedColor = updatedProduct.color_options.find((c) => c.color_name === colorOption.color_name);
            if (savedColor) {
              colorsToUpload = true;
              this.uploadImages(savedColor.id!, colorOption.new_images, index === (product.color_options?.filter((f) => f.new_images?.length && f.new_images.length > 0).length - 1));
            }
          }
        });
        if (!colorsToUpload) {
          this.product = updatedProduct;
          var products = this.productsService.productList.value;
          const index = products.findIndex((p) => p.id === this.product!.id)
          if (index > -1) {
            console.log('INDEX TO CHANGE:', index)
            products[index] = this.product;
          }
          console.log('NEW LIST:', products)
          console.log('THIS PRODUCT', this.product)
          this.productsService.productList.next(products);
          this.productsService.categoryList.next(this.productsService.categoryList.value)
          this.router.navigate(['management/product-management']);
        }
      },
      error: (error) => {
        if (!environment.production) {
          console.log('Error updating product data', error);
        }
      }
    });
  }

  uploadImages(colorOptionId: number, newImages: { file: File }[], last: boolean): void {
    const formData = new FormData();

    newImages.forEach((image: { file: File, url?: string | ArrayBuffer | null | undefined }, index) => {
      if (image.file) {
        formData.append(`new_images`, image.file);
      }
    });
    for (const [key, value] of (formData as any).entries()) {
      console.log(`${key}:`, value);
    }
    this.http.post<ProductInterface>(
      `${this.constantsService.getApiEndpoint()}/shop/upload-color-option-image-verwaltung/${colorOptionId}/`,
      formData,
      {headers: this.constantsService.getHttpOptionsWithoutContent()}
    ).subscribe({
      next: (result) => {
        console.log('Images uploaded:', result);
        if (last) {
          if (this.editMode) {
            this.product = result;
            const products = this.productsService.productList.value;
            const index = products.findIndex((p) => p.id === this.product?.id)
            if (index > -1) {
              products[index] = this.product;
            }
            this.productsService.productList.next(products);
            this.productsService.categoryList.next(this.productsService.categoryList.value)
            this.router.navigate(['management/product-management']);
          } else {
            this.product = result;
            var products = this.productsService.productList.value;
            products.push(this.product);
            this.productsService.productList.next(products);
            this.productsService.categoryList.next(this.productsService.categoryList.value)
            this.router.navigate(['management/product-management']);
          }

        }

      },
      error: (error) => {
        console.error('Error uploading images:', error);
      }
    });
  }

  isEqual() {
    if (this.colorsToDelete.length > 0 || this.imagesToDelete.length > 0) {
      return false;
    }

    return _.isEqual(this.product, this.inputProduct);
  }

  newColor() {
    this.product?.color_options.unshift({
      color_name: '',
      override_images: [],
      override_order_number: '',
      product: this.product.id,
      size_groups: [],
      sizes: [],
    })
  }

  protected readonly faPlus = faPlus;
}
