<div class="product-wrapper">
  <div class="product-image">
    <img [src]="getProductImage()" alt="">
  </div>
  <div class="info">
    <div *ngIf="shoppingCartProduct" class="flex">
      <div class="name">{{shoppingCartProduct.p.n}}</div>
      <div class="row-flex justify-between align-center">
        <div class="bold">Farbe:</div>
        <div class="">{{shoppingCartProduct.c.n}}</div>
      </div>
      <div class="row-flex justify-between align-center">
        <div class="bold">Größe:</div>
        <div class="">{{shoppingCartProduct.s.n}}</div>
      </div>
      <div class="row-flex justify-between align-center">
        <div class="bold">Preis:</div>
        <div class="price">{{shoppingCartProduct.p.p}} Punkte</div>
      </div>
      <div class="row-flex justify-between align-center mb-3">
        <div class="bold">Anzahl</div>
        <div *ngIf="managementMode">{{shoppingCartProduct.q}}</div>
        <div *ngIf="!managementMode" class="quantity"><app-quantity-selector (newQuantity)="updateQuantity($event)"
                                                     [initial]="shoppingCartProduct.q"
                                                     [min]="1"
                                                     [plusDisabled]="!canAddItem()"></app-quantity-selector></div>
      </div>
      <button *ngIf="!managementMode" title="Produkt löschen" (click)="deleteItem()"
               class="btn btn-sm btn-danger trash"><fa-icon [icon]="trashItem"></fa-icon></button>
    </div>
  </div>
</div>
