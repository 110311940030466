import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  ProductColorInterface,
  ProductInterface, ProductSizeInterface,
  SizeGroupManagementInterface
} from '../../../../../../models/product.interface';
import {Subscription} from 'rxjs';
import {ProductsService} from '../../../../../../services/products.service';
import {NgForOf} from '@angular/common';
import {ShowBooleanComponent} from '../../../../../../tools/show-boolean/show-boolean.component';

@Component({
  selector: 'app-size-selection',
  standalone: true,
  imports: [
    NgForOf,
    ShowBooleanComponent
  ],
  templateUrl: './size-selection.component.html',
  styleUrl: './size-selection.component.css'
})
export class SizeSelectionComponent implements OnInit, OnDestroy{
  sizes?: SizeGroupManagementInterface[];
  sizeSub: Subscription;
  @Input() color: ProductColorInterface | undefined;
  @Output() sizeChanged = new EventEmitter<ProductSizeInterface>();

  constructor(private productsService: ProductsService) {
    this.sizeSub = productsService.sizeGroupsList.subscribe(
      (sizeGroups) => {
        this.sizes = sizeGroups;
      }
    )

  }

  ngOnInit() {
  }

  checked(id: number)  {
    return !!this.color?.sizes.find((c) => c.id === id);

  }


  ngOnDestroy() {
    this.sizeSub.unsubscribe();
  }

}
