<div class="shopping-cart-wrapper">
  <div class="cart">
    <h3 class="rail-theme">Warenkorb ({{getArticleCount()}} Artikel)</h3>
    <div class="cart-items-list custom-scrollbar" *ngIf="shoppingCart">
      <div class="cart-item" *ngFor="let item of shoppingCart.cart">
        <app-shopping-cart-item-view (cartUpdated)="cartUpdated()" [shoppingCartProduct]="item"></app-shopping-cart-item-view>
      </div>
    </div>
  </div>
  <div class="right-side">
    <div class="infos">
      <div class="update-info" *ngIf="showUpdateBox" @inOutAnimation>
        <fa-icon [icon]="checkIcon"></fa-icon> Warenkorb angepasst!
      </div>
      <h3 class="rail-theme">Zusammenfassung</h3>
      <div class="s-row">
        <span>Verfügbare Punkte</span><span>{{shoppingCart?.credits_available}}</span>
      </div>
      <div class="s-row">
        <span>Summe der Waren</span><span>{{getSum()}}</span>
      </div>
      <div class="s-row">
        <span>Verbleibende Punkte</span><span>{{shoppingCart?.credits_remaining}}</span>
      </div>
    </div>
    <div class="info-text">
      <b>Vielen Dank für Ihre Auswahl.</b><br>Sie können sich jetzt ausloggen oder im Shop weitere Artikel auswählen. Ihr
        Warenkorb wird bis zum Bestelltag
        (28./29. Februar bzw. 31. August d. J.) gespeichert und ist bis dahin beliebig änder- und ergänzbar. Erst zum Bestellstichtag erfolgt der Abruf
        durch die railmen-Zentrale und wird bei Engelbert Strauss zentral bestellt. Bei Rückfragen oder Problemen wenden Sie sich bitte an Susanne Sämann.
    </div>
  </div>
</div>
