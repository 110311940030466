import {Component, OnInit} from '@angular/core';
import {ManagementUserAndCartInterface, ShoppingCartInterface} from '../../../models/shopping-cart.interface';
import {ConstantsService} from '../../../services/constants.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ShopUserForListInterface} from '../../../models/shop-user.interface';
import {faRightFromBracket, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {NgClass, NgForOf, NgIf, NgStyle} from '@angular/common';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {
  ShoppingCartItemViewComponent
} from '../../shopping-cart/shopping-cart-item-view/shopping-cart-item-view.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-user-shopping-carts',
  standalone: true,
  imports: [
    FaIconComponent,
    NgIf,
    NgForOf,
    AccordionModule,
    ShoppingCartItemViewComponent,
    NgClass,
    NgStyle
  ],
  templateUrl: './user-shopping-carts.component.html',
  styleUrl: './user-shopping-carts.component.css'
})
export class UserShoppingCartsComponent implements OnInit{
  users: ShopUserForListInterface[] = [];
  loading: boolean = true;

  constructor(private constantsService: ConstantsService,
              private http: HttpClient,
              private router: Router,
              private route: ActivatedRoute) {
    this.loadUserList();
  }

  ngOnInit() {
    this.loadUserList();
  }

  loadUserList() {
    this.http.get<ShopUserForListInterface[]>(`${this.constantsService.getApiEndpoint()}/shop/user-list/`, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        let newUsers = result;
        newUsers = newUsers.sort((a,b) =>(a.last_name < b.last_name? -1 : 1));
        this.users = newUsers;
        this.loading = false;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading user list', error);
        }
      }
    });
  }

  goToUser(user: ShopUserForListInterface) {
    this.router.navigate([`${user.id}`], {relativeTo: this.route});
  }

  protected readonly faSpinner = faSpinner;
  protected readonly Object = Object;
}
