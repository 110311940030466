<button class="btn btn-sm btn-primary mb-1" (click)="toLogin()"><fa-icon [icon]="backArrow"></fa-icon> Zurück zum
  Login</button>
<h3 class="rail-theme">Neues Passwort setzen</h3>
<div *ngIf="!validToken && testedToken">
  <div>
    Der Bestätigungslink ist nicht mehr gültig. Bitte Beantragen sie einen neuen Link!
  </div>
</div>
<div *ngIf="validToken && testedToken">
  <div class="info">
    Bitte setzen Sie ein neues starkes Passwort für Ihren Account!
  </div>
  <form [formGroup]="form">
    <div class="inputs">
      <label class="form-label" for="password">Passwort</label>
      <input placeholder="starkes Passwort" class="form-control" formControlName='newPassword' id="password"
             type="password">
      <div class="errors">
        <div class="block" *ngIf="!form.get('newPassword')!.value!.match('.{8,}')">Mindestens 8 Zeichen lang.</div>
        <div class="block" *ngIf="!form.get('newPassword')!.value!.match('^(?=.*[A-Z])')">Mindestens ein Großbuchstabe
          .</div>
        <div class="block" *ngIf="!form.get('newPassword')!.value!.match('(?=.*[a-z])')">Mindestens ein Kleinbuchstabe
          .</div>
        <div class="block" *ngIf="!form.get('newPassword')!.value!.match('(.*[0-9].*)')">Mindestens eine Zahl.</div>
        <div class="block" *ngIf="!form.get('newPassword')!.value!.match('(?=.*[!@#$%^&*`~_/./-])')">Mindestens ein
          Sonderzeichen
          .</div>
      </div>
      <label class="form-label" for="repeat-password">Passwort wiederholen</label>
      <input placeholder="starkes Passwort" class="form-control" formControlName='repeatPassword' id="repeat-password"
             type="password">
      <div class="errors">
        <div class="block" *ngIf="form.get('repeatPassword')!.invalid">Passwort stimmt nicht überein!</div>
      </div>
    </div>
  </form>
  <div *ngIf="requested && success" class="success">
  <span><fa-icon [icon]="checkIcon"></fa-icon> neues Passwort gesetzt! Sie können sich nun mit Ihrem neuen
    Passwort einloggen</span>
  </div>
  <div *ngIf="requested && !success" class="error">
  <span><fa-icon [icon]="exIcon"></fa-icon> Fehler beim Setzen des Passworts. Versuchen Sie es erneut oder
    kontaktieren Sie den Support!</span>
  </div>
  <button *ngIf="!success" [disabled]="!form.valid" (click)="setNewPassword()" class="btn btn-sm btn-success m-1"><span
    *ngIf="!loading">Neues
  Passwort setzen</span>
    <fa-icon *ngIf="loading" [icon]="spinnerIcon" animation="spin-pulse"></fa-icon></button>
  <button *ngIf="requested && success" class="btn btn-sm btn-primary m-1" (click)="toLogin()"><fa-icon
    [icon]="backArrow"></fa-icon> Zurück zum
    Login</button>
</div>
