import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule, ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {HttpClient} from '@angular/common/http';
import {ConstantsService} from '../../services/constants.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {faArrowLeft, faCheck, faExclamation, faSpinner} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-set-new-password',
  standalone: true,
  imports: [CommonModule, FaIconComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './set-new-password.component.html',
  styleUrl: './set-new-password.component.css'
})
export class SetNewPasswordComponent {
  form: FormGroup;
  backArrow = faArrowLeft;
  spinnerIcon = faSpinner;
  checkIcon = faCheck;
  exIcon = faExclamation;
  loading = false;
  requested = false;
  success = false;
  validToken = false;
  testedToken = false
  token = '';

  public constructor(private http: HttpClient,
                     private constantsService: ConstantsService,
                     private router: Router,
                     private route: ActivatedRoute) {
    this.form = new FormGroup(
      {
        newPassword: new FormControl('', {validators: [Validators.maxLength(20), this.customValidatorPasswordStrenghtFn()]}),
        repeatPassword: new FormControl('', {validators: [this.customValidatorExactMatchFn()]})
      }
    )
    this.route.paramMap.subscribe((params: ParamMap) => {
      const token = params.get('token');
      this.checkIfTokenIsValid(token!);

    })
  }

  customValidatorExactMatchFn(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.form) {
        console.log('check:', this.form.get('newPassword')!.value === control.value)
        const valid: boolean = this.form.get('newPassword')!.value === control.value;
        return valid ? null : {notSamePasswordError: true};
      }
      return {formNotLoadedError: true}
    };
  }

  private customValidatorPasswordStrenghtFn(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid: boolean = this.passwordStrength(control.value) >= 12;
      return valid ? null : {passwordStrengthError: true};
    };
  }

  setNewPassword() {
    this.loading = true;
    const body = {
      reset_token: this.token,
      password: this.form.get('newPassword')!.value
    };
    this.http.post(`${this.constantsService.getApiEndpoint()}/shop/change-password/`, body, {headers: this.constantsService.getHttpOptionsForLogin()}).subscribe({
      next: (result) => {
        this.success = true;
      },
      error: (error) => {
        this.success = false;
      },
      complete: () => {
        this.loading = false;
        this.requested = true;
      }
    });
  }

  passwordStrength(password: string) {
    let strength = 0;
    if (password.match('^(?=.*[A-Z])')) {  // one uppercase letter
      strength += 1;
    }
    if (password.match('(?=.*[a-z])')) {  // one lowercase letter
      strength += 1;
    }
    if (password.match('(.*[0-9].*)')) {  // at least one digit
      strength += 1;
    }
    if (password.match('(?=.*[!@#$%^&*`~_/./-])')) {  // at least one special character
      strength += 1;
    }
    if (password.match('.{8,}')) {  // at least 8 characters long
      strength += 10;
    }

    return strength
  }
  toLogin() {
    this.router.navigate(['login']);
  }

  checkIfTokenIsValid(token: string) {
    const body = {
      reset_token: token
    }
    this.http.post(`${this.constantsService.getApiEndpoint()}/shop/validate-password-reset/`, body, {headers: this.constantsService.getHttpOptionsForLogin()}).subscribe({
      next: (result) => {
        this.validToken = true;
        this.token = token;
        this.testedToken = true;
      },
      error: (error) => {
        this.testedToken = true;
      },
      complete: () => {
      }
    });

  }

}
