import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular-ivy";
import { appConfig } from './app/app.config';
import {AppComponent} from "./app/app.component";
import {environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";

Sentry.init({
  dsn: "https://93c51662eb9fce70dc270f05cd2d6005@fehlerteufel.fuzztech.de/11",
  integrations: [
    new Sentry.BrowserTracing({
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

if (environment.production) {
  enableProdMode();
}
bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
