<h3 class="rail-theme header">Kategorien</h3>
<div class="welcome-message">
    <div>Die farblichen Abbildungen dienen als Musterbeispiel für den Bestellartikel. Beim Öffnen des
Artikelfensters kann zwischen den ggf. verschiedenen bestellbaren Farben ausgewählt werden.</div>
</div>
<div class="category-list">
  <div (click)="selectCategory(c.id)" class="category-card" *ngFor="let c of categories">
    <div class="image-container">
      <img [src]="getImageUrl(c.category_image)" alt="">
    </div>
    <div class="name">{{c.category_name}}</div>
  </div>
</div>
