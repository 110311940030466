import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Router, RouterOutlet} from '@angular/router';
import {ShopTopMenuComponent} from './shop/shop-top-menu/shop-top-menu.component';
import {AuthService} from './services/auth.service';
import {authGuard} from './auth.guard';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ShopTopMenuComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'Railmen Mitarbeiter-Bestellservice';
  public constructor(private router: Router,
                     private authService: AuthService) {
  }

  showTopMenu() {
    return true;
    // return this.authService.isLoggedIn;
  }
}
