import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  ProductColorInterface, ProductSizeGroupInterface,
  ProductSizeInterface,
  SizeGroupManagementInterface
} from '../../../../../models/product.interface';
import {ProductsService} from '../../../../../services/products.service';
import {NgxFileDropEntry, NgxFileDropModule} from 'ngx-file-drop';
import {SizeSelectionComponent} from './size-selection/size-selection.component';

@Component({
  selector: 'app-single-color',
  standalone: true,
  imports: [
    FaIconComponent,
    NgForOf,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    NgClass,
    NgxFileDropModule,
    SizeSelectionComponent
  ],
  templateUrl: './single-color.component.html',
  styleUrl: './single-color.component.css'
})
export class SingleColorComponent implements OnInit{
  editMode:boolean = false;
  showSizes = false;
  delete = false;
  @Input() color!: ProductColorInterface;
  @Input() deleteImages!: number[];
  @Output() colorDeleted = new EventEmitter<boolean>();
  @Output() colorNOTDeleted = new EventEmitter();
  @Output() imageDeleted = new EventEmitter<number>();
  @Output() imageNOTDeleted = new EventEmitter<number>();

  constructor(private productsService: ProductsService,) {

  }

  ngOnInit() {
    if (this.color.id) {
      this.editMode = true;
    }
    console.log(this.color);
  }

  deleteImage(id: number) {
    if (this.color.delete_images) {
      this.color.delete_images.push(id);
    } else {
      this.color.delete_images = [id];
    }
    this.imageDeleted.emit(id);
  }

  notDeleteImage(id: number) {
    if (this.color.delete_images) {
      this.color.delete_images = this.color.delete_images.filter((i) => i !== id);
    }
    this.imageNOTDeleted.emit(id);
  }

  deleteAll() {
    this.delete = true;
    this.colorDeleted.emit(this.editMode);
  }

  dontDelete() {
    this.delete = false;
    this.colorNOTDeleted.emit();
  }

  fileOver(event: any) {
  }

  fileLeave(event: any) {
  }

  sizeChanged(size: ProductSizeInterface) {
    if (this.color.sizes.find((s) => s.id === size.id)) {
      this.color.sizes = this.color.sizes.filter((s) => s.id !== size.id);
    } else {
      this.color.sizes.push(size);
    }
    this.checkSizeGroups();
  }

  checkSizeGroups() {
    const sizeGroupsInSizes: number[] = []
    for(const size of this.color.sizes) {
      if (!sizeGroupsInSizes.includes(size.product_size_group)) {
        sizeGroupsInSizes.push(size.product_size_group);
      }
    }
    for (const sg of sizeGroupsInSizes) {
      if (!this.color.size_groups.find((s) => s.id === sg)) {
        if (this.getSizeGroup(sg)){
          this.color.size_groups.push(this.getSizeGroup(sg)!)
        }
      }
    }
    this.color.size_groups = this.color.size_groups.filter((sg) => this.color.sizes.findIndex((c) => c.product_size_group === sg.id) > -1);
  }

  getSizeGroup(id: number) {
    const mangementGroup: SizeGroupManagementInterface | undefined = this.productsService.sizeGroupsList.value.find((sg) =>sg.id === id);
    if (mangementGroup) {
      const sizeGroup: ProductSizeGroupInterface = {
        id: mangementGroup.id,
        size_group_name: mangementGroup.size_group_name
      }
      return sizeGroup;
    }
    return undefined;
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.relativePath.includes('.png') || droppedFile.relativePath.includes('.jpg') || droppedFile.relativePath.includes('.jpeg'))
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            var reader = new FileReader();
            reader.readAsDataURL(file); // read file as data url
            reader.onload = (e) => { // called once readAsDataURL is completed
              // this.files.push({file: file, url: e.target?.result});
              if (this.color.new_images) {
                this.color.new_images.push({file: file, url: e.target?.result});
              } else {
                this.color.new_images = [{file: file, url: e.target?.result}]
              }
            }
          });
        }
    }

  }

  deleteNewImage(image: {file: File, url?: string | ArrayBuffer | null | undefined}) {
    this.color.new_images = this.color.new_images!.filter((f) => f !== image);
  }

  getProductImage(id: number) {
    return this.productsService.getImageUrl(id)
  }

  protected readonly faTrash = faTrash;
}
