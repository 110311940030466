<h3 class="rail-theme my-3 move-in">Bestell-Liste
  <button (click)="back()" class="btn btn-sm btn-primary mx-3">zurück</button>
  <button class="btn btn-sm btn-success"
          (click)="printOrderList()"
  >Excel-Liste
    drucken
  </button>
</h3>
<div *ngIf="loading">
  <fa-icon [icon]="faSpinner"></fa-icon>
</div>
<b *ngIf="!loading && orderList">Stand: {{ orderList.datum }}</b>
<div *ngIf="!loading && orderList" class="order-list basic-card custom-scrollbar">
  <table class="table">
    <thead class="head">
    <tr>
      <th>Art.-Nr</th>
      <th>Bezeichnung</th>
      <th>Farbe</th>
      <th>Größe</th>
      <th>Stück</th>
      <th>Besteller</th>
      <th>Budgetabgang</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of orderList.rows">
      <td class="r b">{{ row.article_number }}</td>
      <td class="b">{{ row.product_name }}</td>
      <td>{{ row.color_name }}</td>
      <td>{{ row.size_name }}</td>
      <td>{{ row.quantity }}</td>
      <td>{{ row.user_name }}</td>
      <td>{{ row.costs }}</td>
    </tr>
    <tr class="bottom">
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td class="b">{{orderList.count_sum}}</td>
      <td></td>
      <td class="b">{{orderList.budget_sum}}</td>
    </tr>
    </tbody>
  </table>
</div>
