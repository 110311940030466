import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ProductColorInterface,
  ProductInterface,
  ProductSizeGroupInterface,
  ProductSizeInterface
} from '../../models/product.interface';
import {ProductsService} from '../../services/products.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {CarouselComponent, CarouselModule} from 'ngx-bootstrap/carousel';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {QuantitySelectorComponent} from '../../tools/quantity-selector/quantity-selector.component';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faAngleRight, faCartPlus, faCheck} from '@fortawesome/free-solid-svg-icons';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {SCartColorAndSizeInfoInterface, SCartProductInfoInterface} from '../../models/shopping-cart.interface';
import {animate, style, transition, trigger} from '@angular/animations';
import {timeout} from 'rxjs';

@Component({
  selector: 'app-single-item-full-view',
  standalone: true,
  imports: [CommonModule, CarouselModule, ReactiveFormsModule, QuantitySelectorComponent, FaIconComponent],
  animations:[
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0 }),
            animate('1s ease-out',
                    style({opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1 }),
            animate('1s ease-in',
                    style({opacity: 0 }))
          ]
        )
      ]
    )
  ],
  templateUrl: './single-item-full-view.component.html',
  styleUrl: './single-item-full-view.component.css'
})
export class SingleItemFullViewComponent implements OnInit, AfterViewInit{
  product: ProductInterface | undefined;
  form: FormGroup;
  addToCartIcon = faCartPlus
  rightIcon = faAngleRight;
  checkIcon = faCheck;
  picturesToShow: number[] = [];
  colorOptions: ProductColorInterface[] = [];
  sizeGroupOptions: ProductSizeGroupInterface[] = [];
  sizeOptions: ProductSizeInterface[] = [];
  quantity = 0;
  showCartInfo = false;
  @ViewChild(CarouselComponent) carousel: CarouselComponent | undefined;

  public constructor(private productsService: ProductsService,
                     private router: Router,
                     private route: ActivatedRoute,
                     private shoppingCartService: ShoppingCartService) {
    this.form = new FormGroup({
      color: new FormControl(0),
      sizeGroup: new FormControl(0),
      size: new FormControl(0)
    })
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      this.product = this.productsService.getSingleProduct(id!);
      this.colorOptions = this.product!.color_options;
      this.checkColorOptionsAndSetForm();
    })

    this.form.get('color')?.valueChanges.subscribe(
      (value) => {
        const pcs = this.colorOptions.find(c => c.id == value)?.override_images
        this.setPicturesToShow(pcs!);
        this.checkSizeGroupOptionsAndSetForm();
      }
    )

    this.form.get('sizeGroup')?.valueChanges.subscribe(
      (value) => {
        this.checkSizeOptionsAndSetForm()
      }
    )

  }

  ngAfterViewInit() {
  }

  getImageUrl(id: number) {
    return this.productsService.getImageUrl(id);
  }

  updateQuantity(newQuantity: number) {
    this.quantity = newQuantity;
  }

  getCategoryName() {
    return this.productsService.getCategoryNames(this.product!.product_category);
  }

  checkColorOptionsAndSetForm() {
    this.form.get('color')?.setValue(this.colorOptions[0].id)
    this.setPicturesToShow(this.colorOptions[0].override_images)
    this.checkSizeGroupOptionsAndSetForm()
  }

  checkSizeGroupOptionsAndSetForm() {
    this.sizeGroupOptions = this.colorOptions.find(o => o.id == this.form.get('color')?.value)!.size_groups;
    this.form.get('sizeGroup')?.setValue(this.sizeGroupOptions[0].id);
    this.checkSizeOptionsAndSetForm()
  }

  checkSizeOptionsAndSetForm() {
    const sizeGroup = this.sizeGroupOptions.find(sg => sg.id == this.form.get('sizeGroup')?.value)
    const color = this.colorOptions.find(c => c.id == this.form.get('color')?.value)
    const sizeOptions = color?.sizes.filter(s => s.product_size_group === sizeGroup?.id)
    this.sizeOptions = sizeOptions!;
    this.form.get('size')?.setValue(this.sizeOptions[0].id);
  }

  setPicturesToShow(pics: number[]) {
    this.picturesToShow = pics;
    this.carousel?.selectSlide(0);
  }

  addItemToCart() {
    console.log('cOptions:',this.colorOptions)
    console.log('cValue:',this.form.get('color')!.value)
    console.log('cName:',this.colorOptions.find(c => c.id ==this.form.get('color')!.value)!.color_name)
    const pInfo: SCartProductInfoInterface = {
      id: this.product!.id!,
      n: this.product!.product_name,
      p: this.product!.price_points,
      pic: this.picturesToShow[0]

    }
    const cInfo: SCartColorAndSizeInfoInterface = {
      id: this.form.get('color')!.value,
      n: this.colorOptions.find(c => c.id == this.form.get('color')!.value)!.color_name
    }
    const sInfo: SCartColorAndSizeInfoInterface = {
      id: this.form.get('size')!.value,
      n: this.sizeOptions.find(s => s.id == this.form.get('size')!.value)!.size_name
    }
    this.shoppingCartService.addItemToCart(pInfo, cInfo, sInfo, this.quantity);
    this.showCartInfo = true;
    setTimeout(() => {
      this.showCartInfo = false;
    }, 3000)
  }

  canAddItem() {
    return this.shoppingCartService.checkIfEnoughPointsAvailable(this.shoppingCartService.shoppingCart.value!, (this.product!.price_points * (this.quantity + 1)))
  }

  disableBuyButton() {
    return !(this.shoppingCartService.checkIfEnoughPointsAvailable(this.shoppingCartService.shoppingCart.value!, (this.product!.price_points * this.quantity)) && (this.quantity > 0))
  }

  goToCategoryPage() {
    this.router.navigate(['category']);
  }

  goToProductsListPage() {
    this.router.navigate(['products-list']);
  }

  getAvailablePoints() {
    return this.shoppingCartService.getAvailablePoints();
  }

  showSizeGroup() {
    return this.sizeGroupOptions.length > 1;
  }
}
