import {Component, Input} from '@angular/core';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'app-show-boolean',
  standalone: true,
  imports: [
    FaIconComponent,
    NgIf,
    NgClass
  ],
  templateUrl: './show-boolean.component.html',
  styleUrl: './show-boolean.component.css'
})
export class ShowBooleanComponent {
  @Input() value!: boolean;

  protected readonly faCheck = faCheck;
}
