import {Inject, Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpHeaders} from "@angular/common/http";
declare var require: any;
const { version: appVersion } = require('../../../package.json');

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  constructor() {}

  getApiVersion() {
    return appVersion;
  }

  getApiEndpoint(): string {
    const path = '/backend';
    return this.getApiServer() + path;
  }

  getApiServer(): string {
    const apiServer = environment.backendURL;
    return apiServer;
  }

  getHttpOptionsForLogin() {
    return new HttpHeaders({'Accept': 'application/json', 'Content-Type': 'application/json'});
  }

  getHttpOptions(token: string = '') {
    if (token === '') {
      const t = localStorage.getItem('token'); // if no token is given use the one from local storage
      if (t !== null) {
        token = t;
      } else {
        token = '';
      }
    }
    return new HttpHeaders({'Accept': 'application/json', 'Content-Type': 'application/json',
      'Authorization': 'Token ' + token});
  }

  getHttpOptionsWithoutContent(token: string = '') {
    if (token === '') {
      const t = localStorage.getItem('token'); // if no token is given use the one from local storage
      if (t !== null) {
        token = t;
      } else {
        token = '';
      }
    }
    return new HttpHeaders({'Accept': 'application/json',
      'Authorization': 'Token ' + token});
  }
}
