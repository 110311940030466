<div class="container">
  <div class="form-login">
    <div class="">
      <img class="logo pull-right corners" src="assets/images/logo.png" alt="railmen Logo"/>
    </div>
    <form [formGroup]="loginForm" class="form-horizontal" (ngSubmit)="onFormSubmit()">
      <h4 class="mb-2 systemtheme">Ihr Mitarbeiter-Bestellservice.</h4>
      <p><i>Bitte melden Sie sich mit Ihren Zugangsdaten an</i></p>
      <input autofocus type="text" formControlName="user" id="user" class="form-control form-control-sm my-2"
             placeholder="E-Mail" />
      <input type="password" formControlName="password" id="password" class="form-control form-control-sm my-2" placeholder="Passwort" />
      <p class="pwdlink" (click)="resetPassword()" title="Klicken Sie hier um Ihr Passwort zurückzusetzen">Passwort vergessen?</p>
      <p *ngIf="login_error && !login_in_progress" class="my-2 login-error">{{login_error}}</p>
      <p *ngIf="login_in_progress" class="my-2 login-progress">Anmeldevorgang läuft...</p>
      <button type="submit" class="btn btn-primary btn-sm">einloggen</button>
      <p style="font-size: 70%; font-style: italic;" class="my-2">Railmen-Shop Version {{this.getVersion()}}</p>
    </form>
  </div>
</div>
