<h3 class="rail-theme my-3 move-in">Produktverwaltung
  <button (click)="newProduct()" class="btn btn-sm btn-success mx-3">neues Produkt anlegen</button>
</h3>
<div class="management-content custom-scrollbar">
  <div *ngFor="let c of categoryList">
    <h4 class="rail-theme">{{ c.category_name }}</h4>
    <div class="category-products">
      <app-single-item-small-view [ngClass]="{'inactive': !p.is_active}" class="single-item-block"
                                  (click)="goToProduct(p)"
                                  *ngFor="let p of
      getProductsForCategory(c)"
                                  [product]="p"></app-single-item-small-view>
    </div>
  </div>
</div>

