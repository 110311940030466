import {inject} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

export const authGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isLoggedIn) {
    return true;
  }
  // see https://angular.io/guide/router-tutorial-toh#canactivate-requiring-authentication
  return router.parseUrl('login');
}

export const authGuardManagement = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isLoggedIn && authService.isAllowedToSeeManagement()) {
    return true;
  }
  // see https://angular.io/guide/router-tutorial-toh#canactivate-requiring-authentication
  return router.parseUrl('shop');
}
