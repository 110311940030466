<h3 class="rail-theme my-3 move-in">Nutzerverwaltung <button (click)="newUser()" class="btn btn-sm btn-success mx-3">
  neuen Nutzer anlegen</button></h3>
<div *ngIf="loading"><fa-icon [icon]="faSpinner"></fa-icon></div>
<div *ngIf="!loading">
  <div class="user-list custom-scrollbar">
    <div *ngFor="let u of users" class="basic-card card-hover" (click)="goToUser(u)">
      <div [ngClass]="{'inactive': !u.is_active}">{{u.last_name}}, {{u.first_name}}<span *ngIf="!u.is_active"> (inaktiv)</span></div>
    </div>
  </div>
</div>
