import {Injectable} from '@angular/core';
import {ConstantsService} from './constants.service';
import {HttpClient} from '@angular/common/http';
import {
  SCartColorAndSizeInfoInterface,
  SCartProductInfoInterface,
  ShoppingCartInterface,
  ShoppingCartItemInterface
} from '../models/shopping-cart.interface';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  shoppingCart: BehaviorSubject<ShoppingCartInterface | null> = new BehaviorSubject<ShoppingCartInterface | null>(null)
  public constructor(
    private constantsService: ConstantsService,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  loadShoppingCart() {
    if (this.authService.authUser.value) {
      this.http.get<ShoppingCartInterface>(`${this.constantsService.getApiEndpoint()}/shop/get-shopping-cart-for-user/`, {headers: this.constantsService.getHttpOptions()}).subscribe({
        next: (result) => {
          this.shoppingCart.next(result);
        },
        error: (error) => {
          if (!environment.production) {
            console.log('error loading product list', error);
            setTimeout(() => this.loadShoppingCart(), 1000);
          }
        }
      });
    }
  }

  updateShoppingCart(cart: ShoppingCartInterface) {
    const creditsRemaining = this.calculateRemainingPoints(cart)
    const body = {
      cart: cart.cart,
      credits_remaining: creditsRemaining
    }
    this.http.post<ShoppingCartInterface>(`${this.constantsService.getApiEndpoint()}/shop/update-shopping-cart-for-user/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
        next: (result) => {
          this.shoppingCart.next(result);
          console.log('shoppingCart updated!');
        },
        error: (error) => {
          if (!environment.production) {
            console.log('error updating shopping cart:', error);
          }
        }
      });
  }

  getArticleCount() {
    if (this.shoppingCart.value) {
      let count = 0;
      for (const item of this.shoppingCart.value!.cart) {
        count += item.q
      }
      return count;
    }
    return 0;
  }

  getSum(cart: ShoppingCartInterface) {
    let sum = 0;
    for (let item of cart.cart) {
      sum += item.p.p * item.q;
    }
    return sum;
  }

  calculateRemainingPoints(cart: ShoppingCartInterface) {
    const sum = this.getSum(cart);
    return cart.credits_available - sum;
  }

  checkIfEnoughPointsAvailable(cart: ShoppingCartInterface, price: number) {
    return cart.credits_remaining >= price;
  }

  deleteItem(id: number) {
    let sCart = this.shoppingCart.value
    if (sCart) {
      sCart.cart = sCart.cart.filter(i => i.id !== id);
      this.updateShoppingCart(sCart);
    } else {
      console.log('Error: No Shopping Cart!')
    }
  }

  reduceItemQuantity(itemID: number) {
    const sCart = this.shoppingCart.value;
    if (sCart) {
      const itemIndex = sCart.cart.findIndex(obj => obj.id === itemID);
      if (itemIndex >= 0) {
        sCart.cart[itemIndex].q -= 1;
      }
      this.updateShoppingCart(sCart);
    }
  }

  checkIfSameItemAlreadyInCart(pID: number, cID: number, sID: number) {
    const sCart = this.shoppingCart.value;
    if (sCart) {
      const product = sCart.cart.find(i => i.p.id === pID && i.s.id === sID && i.c.id === cID)
      if (product) {
        return sCart.cart.findIndex(obj => obj.id === product.id);
      }
    }
    return -1;
  }

  addItemToCart(product: SCartProductInfoInterface, color: SCartColorAndSizeInfoInterface, size: SCartColorAndSizeInfoInterface, quantity: number) {
    const sCart = this.shoppingCart.value;
    if (sCart) {
      const itemIndex = this.checkIfSameItemAlreadyInCart(product.id, color.id, size.id);
      if (itemIndex >= 0) {
        sCart.cart[itemIndex].q += quantity;
      } else {
        const newItem: ShoppingCartItemInterface = {
          c: color ,
          id: this.getNewIdForShopingCart(sCart),
          p: product,
          q: quantity,
          s: size
        }
        sCart.cart.push(newItem);
      }
      this.updateShoppingCart(sCart);
    }
  }

  getNewIdForShopingCart(cart: ShoppingCartInterface) {
    let id = 0;
    while (cart.cart.find(i => i.id === id)) {
      id += 1
    }
    return id;
  }

  getAvailablePoints() {
    return this.shoppingCart.value!.credits_remaining;
  }
}
