<h3 class="rail-theme my-3 move-in">Bestell-Liste
  <button (click)="back()" class="btn btn-sm btn-primary mx-3">zurück</button><button class="btn btn-sm btn-success"
                                                                                      (click)="printOrderList()"
  >Liste
    drucken</button></h3>
<div *ngIf="loading"><fa-icon [icon]="faSpinner"></fa-icon></div>
<b *ngIf="!loading && orderList">Stand: {{currentDate | date:'dd.MM.y HH:mm'}}</b>
<div *ngIf="!loading && orderList" class="order-list basic-card custom-scrollbar">
  <div *ngFor="let item of orderList">
    <b>{{item.name}}</b>
    <div *ngFor="let fo of item.farboptionen">
      Farbe: {{fo.name}} (Bestellnummer: {{fo.bestellnummer}})
      <div *ngFor="let so of fo.sizeoptionen">
        Größe {{so.name}}: {{so.quantity}} Stück
      </div>
    </div>
  </div>
</div>
