<div class="wrapper">
  <button [title]="minusTitle" [disabled]="minusDisabled || number === min" (click)="decrease()" class="minus">
    <fa-icon [icon]="minusIcon"></fa-icon>
  </button>
  <div class="number">
    {{ number }}
  </div>
  <button [title]="plusTitle" [disabled]="plusDisabled || number === max" (click)="increase()" class="plus">
    <fa-icon [icon]="plusIcon"></fa-icon>
  </button>
</div>
