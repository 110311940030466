import {Component, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Subscription} from "rxjs";
import {FormControl, Validators, ReactiveFormsModule, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {ConstantsService} from "../../services/constants.service";
import {AuthService} from "../../services/auth.service";
import {ShopUserInterface} from "../../models/shop-user.interface";

@Component({
  selector: 'app-loginscreen',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './loginscreen.component.html',
  styleUrl: './loginscreen.component.css'
})
export class LoginscreenComponent implements OnInit, OnDestroy {
  login_error= '';
  login_in_progress: boolean = false;
  loginForm!: FormGroup;
  auth_user!: ShopUserInterface | null;
  login_in_progress_subscription!: Subscription;
  login_error_subscription!: Subscription;
  auth_user_subscription!: Subscription;

  constructor(private router: Router,
              private constantsService: ConstantsService,
              private authService: AuthService,
              private titleService: Title) {
    this.login_error_subscription = this.authService.logInError.subscribe({
      next: (val) => { this.login_error = val; }
    });
    this.login_in_progress_subscription = this.authService.logInInProgress.subscribe({
      next: (val) => { this.login_in_progress = val; }
    });
    this.auth_user_subscription = this.authService.authUser.subscribe({
      next: (val) => {
        if (val !== null && this.auth_user === null) {
          this.router.navigate(['shop/']);
        }
        this.auth_user = val;
      }
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Railmen Mitarbeiter-Login');
    this.loginForm = new FormGroup({
      'user': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required]),
    });
  }

  ngOnDestroy() {
    this.login_in_progress_subscription.unsubscribe();
    this.login_error_subscription.unsubscribe();
  }

  onFormSubmit() {
    const username: string = this.loginForm.controls['user'].value;
    const password: string = this.loginForm.controls['password'].value;
    this.authService.tryLogin(username, password);
  }

  getVersion() {
    return this.constantsService.getApiVersion();
  }

  resetPassword() {
    this.router.navigate(['request-new-password'])
  }
}
