import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faBars, faRightFromBracket, faXmark} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {ShoppingCartAdhocComponent} from '../shopping-cart/shopping-cart-adhoc/shopping-cart-adhoc.component';

@Component({
  selector: 'app-shop-top-menu',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, ShoppingCartAdhocComponent],
  templateUrl: './shop-top-menu.component.html',
  styleUrl: './shop-top-menu.component.css'
})
export class ShopTopMenuComponent {
  public constructor(private router: Router,
                     private authService: AuthService) {
  }
  menuValue:boolean=false;
  menuIcon = faBars;
  logoutIcon = faRightFromBracket;
  openMenu(){
    this.menuValue =! this.menuValue;
    this.menuIcon = this.menuValue ? faXmark : faBars;
  }
  closeMenu() {
    this.menuValue = false;
    this.menuIcon = faBars;
  }

  switchRoute(route: String) {
    this.router.navigate([route]);
    this.closeMenu()
  }

  logOut() {
    this.authService.logout();
  }

  isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  isAllowedToSeeManagement() {
    return this.authService.isAllowedToSeeManagement();
  }

  toMain() {
    this.router.navigate(['home']);
  }
}
