import { Component } from '@angular/core';
import {ConstantsService} from '../../../services/constants.service';
import {HttpClient} from '@angular/common/http';
import {ShopUserForListInterface} from '../../../models/shop-user.interface';
import {environment} from '../../../../environments/environment';
import {DatePipe, Location, NgForOf, NgIf} from '@angular/common';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {OrderListInterface, OrderListItemInterface} from '../../../models/shopping-cart.interface';

@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [
    FaIconComponent,
    NgIf,
    NgForOf,
    DatePipe
  ],
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.css'
})
export class OrderListComponent {
  loading: boolean = true;
  orderList: OrderListInterface | undefined;
  currentDate: Date;
  constructor(private constantsService: ConstantsService,
              private http: HttpClient,
              private location: Location) {
    this.loadOrderList();
    this.currentDate = new Date();
  }

  loadOrderList() {
    this.http.get<OrderListInterface>(`${this.constantsService.getApiEndpoint()}/shop/order-list/`, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.orderList = result;
        this.loading = false;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading user list', error);
        }
      }
    });
  }

  back() {
    this.location.back();
  }

  printOrderList() {
    const token = localStorage.getItem('token');
    const submitForm = document.createElement('form');
    submitForm.style.display = 'none';
    submitForm.target = 'Bestell-Liste';
    submitForm.method = 'POST';
    submitForm.action = this.constantsService.getApiEndpoint() + '/shop/order-excel/';
    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'api_token';
    formInput.value = token!;
    submitForm.appendChild(formInput);
    document.body.appendChild(submitForm);
    const wind = window.open('', 'Bestell-Liste');
    if (wind) {
      submitForm.submit();
    }
  }

  protected readonly faSpinner = faSpinner;
}
