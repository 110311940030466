import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, NgFor} from '@angular/common';
import {Subscription} from 'rxjs';
import {ProductCategoryInterface} from '../../models/product-category.interface';
import {ProductsService} from '../../services/products.service';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-category-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './category-view.component.html',
  styleUrl: './category-view.component.css'
})
export class CategoryViewComponent implements OnInit, OnDestroy{
  categoriesSub: Subscription;
  categories: ProductCategoryInterface[] = [];

  public constructor(private productsService: ProductsService,
                     private router: Router,
                     private authService: AuthService) {
    // this.productsService.loadCategoryList();
    this.authService.checkIfAddressNeedsCheck()
    this.categoriesSub = this.productsService.categoryList.subscribe(
      (cList) => {
        this.categories = cList;
      }
    )
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.categoriesSub.unsubscribe();
  }

  getImageUrl(id: number) {
    return this.productsService.getImageUrl(id);
  }

  selectCategory(id: number) {
    this.productsService.setActiveFilter([id]);
    this.router.navigate(['products-list']);
  }
}
