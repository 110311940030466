<h3 class="rail-theme my-3 move-in">Allgemeine Verwaltung
</h3>
<div class="management-content custom-scrollbar">
  <div class="new-period">
    <h4>Neue Bestell-Periode starten</h4>
    <div class="new-buttons">
      <button (click)="triggerRemaining()" [disabled]="mainDisable" class="btn btn-sm btn-primary">mit VERBLEIBENDEN Punkten
        starten</button>
      <div *ngIf="showRemainingInfo && !resultMode" class="info-card">
        <h4>ACHTUNG:</h4>
        <div>Soll die aktuelle Bestellperiode wirklich abgeschlossen und die Einkaufswägen archiviert werden? Die
          momentan verfügbare Punktzahl für jeden Mitarbeiter wird dabei nicht verändert. Bitte beachten Sie: <br>
          <b>Dieser Vorgang kann NICHT rückgängig gemacht werden!</b>
          <div class="confirm-buttons">
            <button (click)="remainingCalled()" [disabled]="loading" class="btn btn-sm btn-success">Neue Periode mit
              bestehenden Pkt.
              starten</button>
            <button (click)="cancel()" [disabled]="loading" class="btn btn-sm btn-danger">Vorgang abbrechen</button>
          </div>
        </div>
      </div>
      <div *ngIf="resultMode === 'success'" class="info-card success-info">
        <h4>Erfolgreich!</h4>
        <div>Die Warenkörbe wurden erfolgreich archiviert und die verfügbaren Punkte dabei übernommen. <br>
        Bitte führen Sie die Funktion NICHT erneut aus.</div>
      </div>
      <div *ngIf="resultMode === 'error'" class="info-card error-info">
        <h4>Fehler!</h4>
        <div>Beim Zurücksetzen der Warenkörbe ist ein technischer Fehler aufgetreten. Bitte wenden Sie sich an den
        System-Admin. Es wird empfohlen die Funktionen NICHT erneut zu versuchen, da dies zu fehlerhaften Daten
          füren kann.</div>
      </div>
    </div>
    <div class="new-buttons">
      <button (click)="triggerReset()" [disabled]="mainDisable" class="btn btn-sm btn-primary">mit NEUEN Punkten starten</button>
      <div *ngIf="showResetInfo && !resultMode" class="info-card">
        <h4>ACHTUNG:</h4>
        <div>Soll die aktuelle Bestellperiode wirklich abgeschlossen und die Einkaufswägen archiviert werden? Die
          verfügbare Punktzahl für jeden Mitarbeiter wird dabei auf den Startwert zurück gesetzt. Bitte beachten Sie:
          <br>
          <b>Dieser Vorgang kann NICHT rückgängig gemacht werden!</b>
          <div class="confirm-buttons">
            <button (click)="resetCalled()" [disabled]="loading" class="btn btn-sm btn-success">Neue Periode mit
              bestehenden Pkt.
              starten</button>
            <button (click)="cancel()" [disabled]="loading" class="btn btn-sm btn-danger">Vorgang abbrechen</button>
          </div>
        </div>
      </div>
      <div *ngIf="resultMode === 'success'" class="info-card success-info">
        <h4>Erfolgreich!</h4>
        <div>Die Warenkörbe wurden erfolgreich archiviert und die verfügbaren Punkte zurück gesetzt. <br>
        Bitte führen Sie die Funktion NICHT erneut aus.</div>
      </div>
      <div *ngIf="resultMode === 'error'" class="info-card error-info">
        <h4>Fehler!</h4>
        <div>Beim Zurücksetzen der Warenkörbe ist ein technischer Fehler aufgetreten. Bitte wenden Sie sich an den
        System-Admin. Es wird empfohlen die Funktionen NICHT erneut zu versuchen, da dies zu fehlerhaften Daten
          füren kann.</div>
      </div>
    </div>
  </div>
</div>
