<div class="management-wrapper">
  <div class="sidebar-wrapper">
    <ul class="sidebar" [class.management_menu]="menuOpen">
      <div class="sidebar-icon" (click)="openMenu()">
        <fa-icon [icon]="menuIcon"></fa-icon>
      </div>
      <div class="round-edge-wrapper">
        <div class="round-edge"></div>
      </div>
      <li class="sidebar-item" (click)="navigateTo('general-management')">Allgemein</li>
      <li class="sidebar-item" (click)="navigateTo('user-management')">Nutzer</li>
      <li class="sidebar-item" (click)="navigateTo('product-management')">Produkte</li>
      <li class="sidebar-item" (click)="navigateTo('order-list')">Bestell-Liste</li>
    </ul>
  </div>
  <div class="main">
    <router-outlet></router-outlet>
  </div>
</div>
