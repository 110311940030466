<div class="sizeinfo">
Die Größen <b>regulär</b>, <b>untersetzt</b> oder <b>schlank</b> nur benutzen, sofern die Unterteilungen für dieses
  Produkt vorhanden sind! Sollte dies nicht der Fall
  sein, bitte den allgemeinen "Größe"-Reiter verwenden!
</div>
<div class="size-table basic-card">
  <div *ngFor="let sg of sizes" class="size-column">
    <div class="size-header">
      <b>{{sg.size_group_name}}</b>
    </div>
    <div *ngFor="let s of sg.sizes_for_group" class="size">
      <app-show-boolean (click)="sizeChanged.emit(s)" [value]="checked(s.id)"
                        id="{{s.id}}"></app-show-boolean><label (click)="sizeChanged.emit(s)"
      class="label-gap"
      for="{{s.id}}">{{s.size_name}}</label>
    </div>
  </div>
</div>
