<div *ngIf="product">
  <h3 class="rail-theme header bottom">
    <span (click)="goToCategoryPage()" class="categories history">Kategorien</span>
    <span class="history"><fa-icon [icon]="rightIcon"></fa-icon></span>
    <span (click)="goToProductsListPage()" class="categories history">{{ getCategoryName() }}</span>
    <span class="history"><fa-icon [icon]="rightIcon"></fa-icon></span>
    {{ product.product_name }}
  </h3>
  <div class="product-site">
    <div class="carousel">
      <carousel [isAnimated]="true" [interval]="0" #carouselComponent>
        <slide *ngFor="let img of picturesToShow">
          <div class="image-center carousel">
            <img [src]="getImageUrl(img)" alt="{{product.product_name}}" class="image">
          </div>
        </slide>
      </carousel>
    </div>
    <div class="shopping-info">
      <div class="category-name">{{getCategoryName()}}</div>
      <div class="product-name">{{product.product_name}}</div>
      <div class="product-price">{{product.price_points}} Punkte</div>
      <form [formGroup]="form" class="inputs">
        <label class="form-label" for="color-select">Farbe</label>
        <select class="form-control" name="color" id="color-select" formControlName="color">
          <option *ngFor="let o of colorOptions" [value]="o.id">{{o.color_name}}</option>
        </select>
        <label *ngIf="showSizeGroup()" class="form-label" for="">Größen-Gruppe</label>
        <select *ngIf="showSizeGroup()" class="form-control" name="size-group" id="size-group-select" formControlName="sizeGroup">
          <option *ngFor="let sg of sizeGroupOptions" [value]="sg.id">{{sg.size_group_name}}</option>
        </select>
        <label class="form-label" for="size-select">Größe</label>
        <select class="form-control" name="size" id="size-select" formControlName="size">
          <option *ngFor="let s of sizeOptions" [value]="s.id">{{s.size_name}}</option>
        </select>
        <label class="form-label" for="size-select">Stückzahl</label>
      </form>
      <div class="row add-to-cart">
        <app-quantity-selector [plusDisabled]="!canAddItem()" [plusTitle]="!canAddItem() ?
        'Nicht genügend Punkte verfügbar' : 'Anzahl erhöhen'" [minusTitle]="'Anzahl verringern'"
                               [initial]="0" [min]="0"
                               (newQuantity)="updateQuantity($event)"
                               class="col-6"></app-quantity-selector>
        <div class="col-6">
          <button [disabled]="disableBuyButton()" (click)="addItemToCart()"
                  class="add-button btn btn-sm btn-success"><fa-icon [icon]="addToCartIcon"></fa-icon></button>
        </div>
      </div>
      <div class="update-info" *ngIf="showCartInfo" @inOutAnimation>
        <fa-icon [icon]="checkIcon"></fa-icon> Zum Warenkorb hinzugefügt!
      </div>
      <div class="available-info">
        Momentan verfügbare Punkte: <span
        [ngClass]="getAvailablePoints() > 0 ? 'green' : 'red'">{{getAvailablePoints()}}</span>
      </div>
    </div>
  </div>

</div>
<div *ngIf="!product">
  Fehler beim Laden des Produktes!
</div>
