import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConstantsService} from "./constants.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {ShopUserInterface} from "../models/shop-user.interface";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authUser: BehaviorSubject<ShopUserInterface | null> = new BehaviorSubject<ShopUserInterface | null>(null);
  authUserData: ShopUserInterface | null = null;
  authUserDataSubscription: Subscription;
  logInInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  logInError: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isLoggedIn = false;

  constructor(
    private http: HttpClient,
    private titleService: Title,
    private router: Router,
    private constantsService: ConstantsService) {
    this.authUserDataSubscription = this.authUser.subscribe(
      (value) => { this.authUserData = value; }
    );
  }

  getToken(username: string, password: string) {
    const body: string = '{"username": "' + username + '", "password": "' + password + '"}';
    const headers = new Headers({'Accept': 'application/json', 'Content-Type': 'application/json'});
    return this.http.post<{
      token: string,
      tenant_id: number
    }>(`${this.constantsService.getApiEndpoint()}/shop/api-token-auth/`, body, {headers: this.constantsService.getHttpOptionsForLogin()});
  }

  tryLogin(username: string, password: string) {
    this.logInInProgress.next(true);
    this.logInError.next('');
    this.getToken(username, password).subscribe({
      next: (response) => {
        const token = response.token;
        localStorage.setItem('token', token);
        this.getUserData();
      },
      error: (error) => {
        this.logInError.next('Die Anmeldedaten sind nicht korrekt.');
        this.logInInProgress.next(false);
        this.isLoggedIn = false;
      },
      complete: () => {
        this.logInInProgress.next(false);
      }
    });
  }

  getUserData() {
    this.http.get<ShopUserInterface>(`${this.constantsService.getApiEndpoint()}/shop/me/`, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.authUser.next(result);
        this.logInError.next('');
        this.logInInProgress.next(false);
        this.isLoggedIn = true;
        this.titleService.setTitle('Railmen Mitarbeiter-Bestellservice');
      },
      error: (error) => {
        this.logInError.next('Es ist ein Fehler bei der Anmeldung aufgetreten.');
        this.logInInProgress.next(false);
        this.isLoggedIn = false;
      },
      complete: () => {
      }
    });
  }

  logout() {
    this.authUser.next(null);
    this.logInInProgress.next(false);
    this.isLoggedIn = false;
    this.titleService.setTitle('Railmen Mitarbeiter-Login');
    this.router.navigate(['login/']);
  }

  checkIfAddressNeedsCheck() {
    let check = false;
    const user = this.authUserData;
    if (user) {
      if (!user.street_address || !user.town || !user.zip_code) {
        this.router.navigate(['profile/true']);
      }
    }
  }

  isAllowedToSeeManagement() {
    return this.isAdmin() || this.isManagement();
  }

  isManagement() {
    return this.authUserData?.user_role == 1;
  }

  isAdmin() {
    return this.authUserData?.user_role == 2;
  }

  updateUserInfo(body: any) {
    return this.http.post<ShopUserInterface>(`${this.constantsService.getApiEndpoint()}/shop/update-profile/`, body, {headers: this.constantsService.getHttpOptions()})
  }

  updateAuthUser(newAuthUser: ShopUserInterface) {
    const actualUser = this.authUserData!;
    actualUser.additional_address_information = newAuthUser.additional_address_information;
    actualUser.updated = newAuthUser.updated;
    actualUser.street_address = newAuthUser.street_address;
    actualUser.telephone_number = newAuthUser.telephone_number;
    actualUser.town = newAuthUser.town;
    actualUser.zip_code = newAuthUser.zip_code;
    this.authUser.next(actualUser);
  }
}
