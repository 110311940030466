<button class="btn btn-sm btn-primary mb-1" (click)="toLogin()"><fa-icon [icon]="backArrow"></fa-icon>Zurück zum
  Login</button>
<h3 class="rail-theme">Neues Passwort beantragen</h3>
<div class="info">
  Wenn Sie ein neues Passwort beantragen wollen, benötigen Sie dafür Ihre E-Mail-Adresse oder Ihren Benutzernamen!
</div>
<form [formGroup]="form">
  <div class="inputs">
    <label class="form-label" for="user-input">Benutzername oder E-Mail</label>
    <input placeholder="Benutzername oder E-Mail" class="form-control" formControlName='userInput' id="user-input"
           type="text">
  </div>
</form>
<div *ngIf="requested && success" class="success">
  <span><fa-icon [icon]="checkIcon"></fa-icon> neues Passwort beantragt! Überprüfen Sie ihre E-Mails und klicken Sie
    auf den Reset-Link!</span>
</div>
<div *ngIf="requested && !success" class="error">
  <span><fa-icon [icon]="exIcon"></fa-icon> Fehler beim Beantragen. Überprüfen Sie Ihre Eingabe!</span>
</div>
<button *ngIf="!requested || !success" [disabled]="!form.valid" (click)="requestNewPassword()" class="btn btn-sm btn-success">Neues Passwort
  beantragen <fa-icon *ngIf="loading" [icon]="spinnerIcon" animation="spin-pulse"></fa-icon></button>
