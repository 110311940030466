import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductsService} from '../../services/products.service';
import {ProductInterface} from '../../models/product.interface';
import {SingleItemSmallViewComponent} from '../single-item-small-view/single-item-small-view.component';
import {Router} from '@angular/router';
import {error} from '@angular/compiler-cli/src/transformers/util';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-product-list-view',
  standalone: true,
  imports: [CommonModule, SingleItemSmallViewComponent, FaIconComponent],
  templateUrl: './product-list-view.component.html',
  styleUrl: './product-list-view.component.css'
})
export class ProductListViewComponent {
  products: ProductInterface[] = [];
  rightIcon = faAngleRight;
  public constructor(private productsService: ProductsService,
                     private router: Router,
                     private authService: AuthService) {
    this.getProductsWithFilter()
    this.authService.checkIfAddressNeedsCheck()
  }

  getProductsWithFilter() {
    this.products = this.productsService.getProductsToView();
  }

  getFilterNames() {
    return this.productsService.getFilterNames();
  }

  showSingleProduct(id: number) {
    this.router.navigate(['product-view', `${id}`]).then(
      nav => {
        console.log(nav)
      }, error => {
        console.log('Error', error)
      }
    )
  }

  goToCategoryPage() {
    this.router.navigate(['category']);
  }
}
