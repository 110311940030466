import { Component } from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {faBars, faPenToSquare, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-management-main',
  standalone: true,
  imports: [
    RouterOutlet,
    FaIconComponent,
    NgIf
  ],
  templateUrl: './management-main.component.html',
  styleUrl: './management-main.component.css'
})
export class ManagementMainComponent {
  menuOpen: boolean = false;
  menuIcon = faPenToSquare;
  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  navigateTo(newRoute: String) {
    this.router.navigate([newRoute], {relativeTo: this.route});
    this.closeMenu();
  }

  openMenu() {
    this.menuOpen =! this.menuOpen;
    this.menuIcon = this.menuOpen ? faXmark : faPenToSquare;
  }

  closeMenu() {
    this.menuOpen = false;
    this.menuIcon = faPenToSquare;
  }

}
