<div class="warning-header">
  <div *ngIf="!editMode" class="new">Neu</div>
  <div *ngIf="color.sizes.length === 0" class="size-warning">Größenauswahl fehlt!</div>
</div>
<div class="top-wrapper">
  <div class="color-info">
    <div>
      <label class="form-label">Name</label>
      <input class="form-control" [(ngModel)]="color.color_name" type="text">
    </div>
    <div>
      <label class="form-label">Bestellnummer</label>
      <input class="form-control" [(ngModel)]="color.override_order_number" type="text">
    </div>
  </div>
  <button *ngIf="!delete" class="btn btn-sm btn-danger mx-1" (click)="deleteAll()">Farbvariante löschen</button>
  <button *ngIf="delete" class="btn btn-sm btn-warning mx-1 index2" (click)="dontDelete()">nicht löschen</button>
</div>
<h5>Bilder</h5>
<div class="color-images">
  <div class="vorschau">
    <div *ngFor="let f of color.override_images" class="product-image" [ngClass]="{'deleted':
    color.delete_images?.includes(f)}">
      <img [src]="getProductImage(f)" alt="">
      <button *ngIf="!color.delete_images?.includes(f)" (click)="deleteImage(f)" class="btn btn-sm btn-danger delete-btn">
        <fa-icon
          [icon]="faTrash"></fa-icon>
      </button>
      <button *ngIf="color.delete_images?.includes(f)" (click)="notDeleteImage(f)"
              class="btn btn-sm btn-warning delete-btn index2">
        <fa-icon
          [icon]="faTrash"></fa-icon>
      </button>
    </div>
    <div *ngFor="let n of color.new_images" class="product-image new-image">
      <img [src]="n.url" alt="Produktbild">
      <button (click)="deleteNewImage(n)" class="btn btn-sm btn-danger delete-btn">
        <fa-icon
          [icon]="faTrash"></fa-icon>
      </button>
    </div>

    <ngx-file-drop [accept]="'image/*'" dropZoneLabel="Datei hierhin schieben" (onFileDrop)="dropped($event)"
                   [dropZoneClassName]="'drop-zone'" [contentClassName]="'drop-content'" [multiple]="false"
                   (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        Datei hierhin schieben oder
        <button class="btn btn-sm btn-primary" (click)="openFileSelector()">Datei
          auswählen
        </button>
      </ng-template>
    </ngx-file-drop>
  </div>
</div>
<h5>Größen
  <span class="size-info" [ngClass]="{'red': color.sizes.length === 0}">({{color.sizes.length}} ausgewählt)</span></h5>
<div class="color-sizes">
  <button class="btn btn-sm btn-success mx-3" (click)="showSizes = !showSizes">Größenzuordnung
    {{ showSizes ? 'ausblenden' : 'anzeigen' }}</button>
  <div *ngIf="showSizes">
    <app-size-selection (sizeChanged)="sizeChanged($event)" [color]="color" *ngIf="showSizes"></app-size-selection>
  </div>
</div>
