import { Component } from '@angular/core';
import {NgForOf, NgIf} from '@angular/common';
import {SingleItemSmallViewComponent} from '../../single-item-small-view/single-item-small-view.component';
import {ConstantsService} from '../../../services/constants.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-general-management',
  standalone: true,
  imports: [
    NgForOf,
    SingleItemSmallViewComponent,
    NgIf
  ],
  templateUrl: './general-management.component.html',
  styleUrl: './general-management.component.css'
})
export class GeneralManagementComponent {
  mainDisable: boolean = false;
  showRemainingInfo: boolean = false;
  showResetInfo: boolean = false;
  loading = false;
  resultMode: string | undefined;

  constructor(private constantsService: ConstantsService,
              private http: HttpClient,) {
  }

  triggerRemaining() {
    this.mainDisable = true;
    this.showRemainingInfo = true;
  }

  triggerReset() {
    this.mainDisable = true;
    this.showResetInfo = true;
  }

  cancel() {
    this.mainDisable = false;
    this.showResetInfo = false;
    this.showRemainingInfo = false;
  }

  remainingCalled() {
    this.loading = true;
    this.http.post(`${this.constantsService.getApiEndpoint()}/shop/reset-period-without-new-points/`, {}, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.resultMode = 'success';
      },
      error: (error) => {
        this.resultMode = 'error';
        if (!environment.production) {
          console.log('error resetting period', error);
        }
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  resetCalled() {
    this.loading = true;
    this.http.post(`${this.constantsService.getApiEndpoint()}/shop/reset-period-with-start-points/`, {}, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.resultMode = 'success';
      },
      error: (error) => {
        this.resultMode = 'error';
        if (!environment.production) {
          console.log('error resetting period', error);
        }
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

}
