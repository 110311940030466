import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ConstantsService} from '../../services/constants.service';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faArrowLeft, faCheck, faExclamation, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {ShopUserInterface} from '../../models/shop-user.interface';
@Component({
  selector: 'app-request-new-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FaIconComponent],
  templateUrl: './request-new-password.component.html',
  styleUrl: './request-new-password.component.css'
})
export class RequestNewPasswordComponent {
  form: FormGroup;
  backArrow = faArrowLeft;
  spinnerIcon = faSpinner;
  checkIcon = faCheck;
  exIcon = faExclamation;
  loading = false;
  requested = false;
  success = false;

  public constructor(private http: HttpClient,
                     private constantsService: ConstantsService,
                     private router: Router) {
    this.form = new FormGroup({
      userInput: new FormControl<string>('', {validators: [Validators.required]})
    })
  }

  requestNewPassword() {
    this.loading = true;
    this.requested = false;
    const body = {
      email: this.form.get('userInput')!.value
    }
    this.http.post(`${this.constantsService.getApiEndpoint()}/shop/request-password-reset/`, body, {headers: this.constantsService.getHttpOptionsForLogin()}).subscribe({
      next: (result) => {
        this.loading = false;
        this.success = true;
        this.requested = true;
      },
      error: (error) => {
        this.loading = false;
        this.success = false;
        this.requested = true;
      },
      complete: () => {}
    });
  }

  toLogin() {
    this.router.navigate(['login']);
  }
}
