import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProductsService} from '../../../services/products.service';
import {NgClass, NgForOf} from '@angular/common';
import {ProductCategoryInterface} from '../../../models/product-category.interface';
import {SingleItemSmallViewComponent} from '../../single-item-small-view/single-item-small-view.component';
import {ShopUserForListInterface} from '../../../models/shop-user.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductInterface} from '../../../models/product.interface';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-product-management',
  standalone: true,
  imports: [
    NgForOf,
    SingleItemSmallViewComponent,
    NgClass
  ],
  templateUrl: './product-management.component.html',
  styleUrl: './product-management.component.css'
})
export class ProductManagementComponent implements OnDestroy, OnInit{
  categoryList: ProductCategoryInterface[] = [];
  categorySub: Subscription | undefined;
  productsList: ProductInterface[] = [];
  productsSub: Subscription | undefined;


  constructor(public productsService: ProductsService,
              private router: Router,
              private route: ActivatedRoute) {
    console.log('Const called!');

  }
  ngOnInit() {
    console.log('ON_INIT CALLED')
    this.categorySub = this.productsService.categoryList.subscribe(
      (categories) => {
        this.categoryList = categories;
      }
    )
    this.productsSub = this.productsService.productList.subscribe(
      (products) => {
        this.productsList = products;
      }
    )
  }

  ngOnDestroy() {
    this.categorySub!.unsubscribe();
    this.productsSub!.unsubscribe();
  }

  newProduct() {
    this.router.navigate([`${-1}`, `new`], {relativeTo: this.route});
  }

  getProductsForCategory(c: ProductCategoryInterface) {
    return this.productsList.filter((p) => p.product_category.includes(c.id));
  }

  goToProduct(product: ProductInterface) {
    this.router.navigate([`${product.id}`, `edit`], {relativeTo: this.route});
  }

}
