<header>
  <div class="container">
    <div class="row">
      <div class="col-md-2 col-sm-2 col-2">
        <div (click)="toMain()" class="logo">
          <img src="assets/images/railmen_logo.webp" class="logo" alt="">
        </div>
      </div>
      <div class="col-md-10 col-sm-10 col-10 text-end" *ngIf="isLoggedIn()">
        <div class="menu-icon" (click)="openMenu()">
          <fa-icon [icon]="menuIcon"></fa-icon>
        </div>
        <div class="desktop_menu" [class.mobile_menu]="menuValue">
          <ul>
            <li *ngIf="!environment.production">TESTSERVER</li>
            <li (click)="switchRoute('shop')">Home</li>
            <li *ngIf="false" (click)="switchRoute('category')">Shop</li>
            <li *ngIf="false" (click)="switchRoute('shopping-cart')">Warenkorb</li>
            <li (click)="switchRoute('profile/false')">Profil</li>
            <li *ngIf="isAllowedToSeeManagement()" (click)="switchRoute('management')">Verwaltung</li>
            <li (click)="logOut()">
              <fa-icon [icon]="logoutIcon"></fa-icon>
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="false" class="shopping-cart-adhoc">
      <app-shopping-cart-adhoc></app-shopping-cart-adhoc>
    </div>
  </div>
</header>
