import {Component, OnInit} from '@angular/core';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {DatePipe, Location, NgClass, NgForOf, NgIf} from '@angular/common';
import {faCheck, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {ConstantsService} from '../../../services/constants.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ShoppingCartInterface} from '../../../models/shopping-cart.interface';
import {environment} from '../../../../environments/environment';
import {ShopUserInterface} from '../../../models/shop-user.interface';
import {
  ShoppingCartItemViewComponent
} from '../../shopping-cart/shopping-cart-item-view/shopping-cart-item-view.component';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {animate, style, transition, trigger} from '@angular/animations';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-single-user-view',
  standalone: true,
  imports: [
    FaIconComponent,
    NgForOf,
    NgIf,
    ShoppingCartItemViewComponent,
    DatePipe,
    NgClass,
    ReactiveFormsModule
  ],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0}),
            animate('1s ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('1s ease-in',
              style({opacity: 0}))
          ]
        )
      ]
    )
  ],
  templateUrl: './single-user-view.component.html',
  styleUrl: './single-user-view.component.css'
})
export class SingleUserViewComponent implements OnInit {
  editMode = false;
  user: ShopUserInterface | undefined;
  shoppingCart: ShoppingCartInterface | undefined;
  shoppingCartSelected: number | undefined;
  shoppingCartList: ShoppingCartInterface[] = [];
  form: FormGroup;
  loading = false
  showUpdateBox = false;

  constructor(private constantsService: ConstantsService,
              private http: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private authService: AuthService,
              private shoppingCartService: ShoppingCartService) {
    this.form = new FormGroup({
      'firstName': new FormControl(''),
      'active': new FormControl(true),
      'userRole': new FormControl(0),
      'lastName': new FormControl(''),
      'email': new FormControl('', [Validators.required]),
      'telephone': new FormControl(''),
      'street': new FormControl(''),
      'info': new FormControl(''),
      'postCode': new FormControl(''),
      'city': new FormControl(''),
    })
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const userID = params.get('id');
      const mode = params.get('mode');
      this.editMode = mode === 'edit';
      if (this.editMode) {
        if (userID) {
          this.loadUser(userID);
          this.loadShoppingCart(userID);
          setTimeout(() => {
            this.loadShoppingCartList(userID);
          }, 700);
        }
      }

    })

  }

  setFormToUser() {
    this.form.patchValue({
      'firstName': this.user?.user.first_name,
      'active': this.user?.user.is_active,
      'userRole': this.user?.user_role,
      'lastName': this.user?.user.last_name,
      'email': this.user?.user.email,
      'telephone': this.user?.telephone_number,
      'street': this.user?.street_address,
      'info': this.user?.additional_address_information,
      'postCode': this.user?.zip_code,
      'city': this.user?.town,
    });
    if (this.disableRole()) {
      this.form.get('userRole')?.disable();
    }
  }

  saveUserInfo() {
    this.loading = true;
    const updatedUser: ShopUserInterface = JSON.parse(JSON.stringify(this.user));
    updatedUser!.user.first_name = this.form.get('firstName')!.value;
    updatedUser!.user.last_name = this.form.get('lastName')!.value;
    updatedUser!.user.is_active = this.form.get('active')!.value;
    updatedUser!.user_role = this.form.get('userRole')!.value;
    updatedUser!.user.email = this.form.get('email')!.value;
    updatedUser!.telephone_number = this.form.get('telephone')!.value;
    updatedUser!.street_address = this.form.get('street')!.value;
    updatedUser!.additional_address_information = this.form.get('info')!.value;
    updatedUser!.zip_code = this.form.get('postCode')!.value;
    updatedUser!.town = this.form.get('city')!.value;
    this.http.post<ShopUserInterface>(`${this.constantsService.getApiEndpoint()}/shop/update-profile-verwaltung/`, updatedUser, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.user = result;
        this.setFormToUser();
        this.userUpdated();
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error updating user-data', error);
        }
      }
    });
    this.loading = false;
  }

  createUser() {
    this.loading = true;
    const newUser: ShopUserInterface = {
      user: {
        username: this.form.get('email')!.value,
        first_name: this.form.get('firstName')!.value,
        last_name: this.form.get('lastName')!.value,
        is_active: this.form.get('active')!.value,
        email: this.form.get('email')!.value,
      },
      telephone_number: this.form.get('telephone')!.value,
      street_address: this.form.get('street')!.value,
      additional_address_information: this.form.get('info')!.value,
      zip_code: this.form.get('postCode')!.value,
      town: this.form.get('city')!.value,
      user_role: this.form.get('userRole')!.value,
      is_active: this.form.get('active')!.value,
    }
    this.http.post<ShopUserInterface>(`${this.constantsService.getApiEndpoint()}/shop/create-profile-verwaltung/`, newUser, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.loadUser(result.id!.toString());
          this.loadShoppingCart(result.id!.toString());
          setTimeout(() => {
            this.loadShoppingCartList(result.id!.toString());
          }, 700);
        this.userUpdated();
        this.editMode = true;
        this.loading = false;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error creating', error);
        }
      }
    });

  }

  loadUser(id: string) {
    const body = {
      user_id: id
    };
    this.http.post<ShopUserInterface>(`${this.constantsService.getApiEndpoint()}/shop/user/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.user = result;
        this.setFormToUser();
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading user-data', error);
        }
      }
    });
  }

  showCart(id: number) {
    this.loadShoppingCartById(id.toString());
  }

  loadShoppingCartById(id: string) {
    const body = {
      cart_id: id
    };
    this.http.post<ShoppingCartInterface>(`${this.constantsService.getApiEndpoint()}/shop/get-shopping-cart-by-id/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.shoppingCart = result
        this.shoppingCartSelected = result.id;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading shoppingcart-by-id', error);
        }
      }
    });
  }

  loadShoppingCart(id: string) {
    const body = {
      shopuser_id: id
    };
    this.http.post<ShoppingCartInterface>(`${this.constantsService.getApiEndpoint()}/shop/get-shopping-cart-for-user/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.shoppingCart = result
        this.shoppingCartSelected = result.id;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading user-shoppingcart', error);
        }
      }
    });
  }

  loadShoppingCartList(id: string) {
    const body = {
      user_id: id
    };
    this.http.post<ShoppingCartInterface[]>(`${this.constantsService.getApiEndpoint()}/shop/get-shopping-cart-list-for-user/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe({
      next: (result) => {
        this.shoppingCartList = result
      },
      error: (error) => {
        if (!environment.production) {
          console.log('error loading user-shoppingcart-list', error);
        }
      }
    });
  }

  userUpdated() {
    this.showUpdateBox = true;
    setTimeout(() => {
      this.showUpdateBox = false;
    }, 3000)
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  isManagement() {
    return this.authService.isManagement();
  }
  disableRole() {
    if (this.authService.authUser.value === undefined || this.user === undefined) {
      return true;
    }
    return this.authService.authUser.value!.user_role < this.user!.user_role;
  }

  getSum() {
    return this.shoppingCartService.getSum(this.shoppingCart!);
  }

  back() {
    this.location.back();
  }

  protected readonly faSpinner = faSpinner;
  protected readonly Object = Object;
  protected readonly checkIcon = faCheck;
}
