import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductsService} from "../../services/products.service";
import {ShopTopMenuComponent} from '../shop-top-menu/shop-top-menu.component';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {faCartShopping, faShirt, faUser} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, FaIconComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  shirtIcon = faShirt;
  cartIcon = faCartShopping;
  profileIcon = faUser;
  constructor(private productsService: ProductsService,
              private shoppingCartService: ShoppingCartService,
              private router: Router,
              private authService: AuthService) {
    this.productsService.init();
    this.shoppingCartService.loadShoppingCart();
    this.authService.checkIfAddressNeedsCheck();
  }

  goTo(route: string) {
    this.router.navigate([route]);
  }

  openSizeTable(type: number) {
    this.productsService.showSizeTable(type);
  }
}
