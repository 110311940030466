<h3 class="rail-theme">
  Profil
</h3>
<div *ngIf="showCeckInfo" class="check-info">
  <div class="check-header">Adresse unvollständig!</div>
  Bevor Sie Ihre Bestellung aufgeben können, müssen Sie Ihre Adresse überprüfen! Es muss eine vollständige Adresse
  (Straße und Hausnummer, Plz., Ort) angegeben sein. Dadurch wird verhindert, dass bestellte Waren aufgrund
  fehlerhafter Angaben nicht geliefert werden können.
</div>
<div *ngIf="!showCeckInfo" class="welcome-message">
    <div>Vor der ersten Bestellung bitte hier die gewünschte Lieferadresse eintragen und
bei evtl. Änderungen (z. B. durch Umzug) rechtzeitig aktualisieren.</div>
</div>
<form [formGroup]="form" class="info-wrapper main-grid">
  <div class="info" >
    <label class="form-label">Vorname</label>
    <input readonly class="form-control" formControlName="firstName" type="text">
    <label class="form-label">Nachname</label>
    <input readonly class="form-control" formControlName="lastName" type="text">
    <label class="form-label">E-Mail-Adresse</label>
    <input readonly class="form-control" formControlName="email" type="text">
    <label class="form-label">Telefon</label>
    <input class="form-control" formControlName="telephone" type="text">
  </div>
  <div class="address">
    <label class="form-label">Straße, Hausnr.</label>
    <input class="form-control" formControlName="street" type="text">
    <label class="form-label">Zusatz</label>
    <input class="form-control" formControlName="info" type="text">
    <label class="form-label">Plz.</label>
    <input class="form-control" formControlName="postCode" type="text">
    <label class="form-label">Ort</label>
    <input class="form-control" formControlName="city" type="text">
  </div>
</form>
<div class="update-info" *ngIf="showUpdateBox" @inOutAnimation>
  <fa-icon [icon]="checkIcon"></fa-icon> Profil gespeichert!
</div>
<button [disabled]="loading" (click)="save()" class="btn btn-sm btn-success save"><fa-icon *ngIf="loading" [icon]="spinnerIcon"
                                                                      animation="spin-pulse"></fa-icon>{{
    showCeckInfo?
  'Profildaten speichern und zum Hauptmenü wechseln':'Profildaten speichern' }}</button>

<h5 class="mt-3">Bestellungen</h5>
<div class="cartlist-wrapper my-2">
  <table class="table table-sm" *ngIf="shoppingCartList && shoppingCartList.length !== 0">
    <tr>
      <th>Erstellung</th>
      <th>letzte Änderung</th>
      <th>Punkte Beginn</th>
      <th>Punkte Ende</th>
      <th>Punkte Verbrauch</th>
      <th>Status</th>
      <th></th>
    </tr>
    <tr *ngFor="let sc of shoppingCartList" [ngClass]="{'selected': sc.id === shoppingCartSelected}">
      <td>{{ sc.created | date: 'dd.MM.y' }}</td>
      <td>{{ sc.updated | date: 'dd.MM.y' }}</td>
      <td>{{sc.credits_available}}</td>
      <td>{{sc.credits_remaining}}</td>
      <td>{{sc.credits_available - sc.credits_remaining}}</td>
      <td>
        <span *ngIf="!sc.archived">aktuell</span>
        <span *ngIf="sc.archived">archiviert</span>
      </td>
      <td>
        <button class="btn btn-sm btn-success" style="background-color: green;" (click)="showCart(sc.id)">auswählen</button>
      </td>
    </tr>
  </table>
  <div *ngIf="!shoppingCartList || shoppingCartList.length === 0">
    keine Bestellungen vorhanden
  </div>
</div>
<h5>Warenkorb <span *ngIf="shoppingCart !== undefined"> vom {{shoppingCart.created | date: 'dd.MM.y'}}
  <span *ngIf="shoppingCart.archived"> (archiviert)</span>
  <span *ngIf="!shoppingCart.archived"> (aktuell)</span>
</span></h5>
<div *ngIf="shoppingCart != undefined && shoppingCartSelected != undefined">
  <div *ngIf="Object.keys(shoppingCart.cart).length === 0">Der Warenkorb ist leer!</div>
  <div *ngIf="Object.keys(shoppingCart.cart).length > 0" class="cart">
    <app-shopping-cart-item-view *ngFor="let item of shoppingCart.cart"
                                 [shoppingCartProduct]="item" [managementMode]="true"></app-shopping-cart-item-view>
  </div>
</div>
<div *ngIf="shoppingCart === undefined">
  <fa-icon [icon]="faSpinner"></fa-icon>
</div>
