<h3 class="rail-theme header bottom">
  <span (click)="goToCategoryPage()" class="categories history">Kategorien</span>
  <span class="history">
    <fa-icon [icon]="rightIcon"></fa-icon></span>
  <span>{{getFilterNames()}}</span>
</h3>
<div class="product-list custom-scrollbar">
  <app-single-item-small-view *ngFor="let p of products" [product]="p"
                              (click)="showSingleProduct(p.id!)"></app-single-item-small-view>
</div>
