<h3 class="rail-theme my-3 move-in">Nutzerverwaltung
  <button (click)="back()" class="btn btn-sm btn-primary mx-3">zurück</button>
</h3>
<h5>Benutzer</h5>
<div class="user-infos">
  <form [formGroup]="form" class="info-wrapper main-grid">
    <div class="info">
      <label class="form-label">Aktiv</label>
      <select class="form-control" formControlName="active" type="text">
        <option [value]="false">Nein</option>
        <option [value]="true">Ja</option>
      </select>
      <label class="form-label">Vorname</label>
      <input class="form-control" formControlName="firstName" type="text">
      <label class="form-label">Nachname</label>
      <input class="form-control" formControlName="lastName" type="text">
      <label class="form-label">E-Mail-Adresse *</label>
      <input class="form-control" formControlName="email" type="text">
      <label class="form-label">Telefon</label>
      <input class="form-control" formControlName="telephone" type="text">
    </div>
    <div class="address">
      <label class="form-label">Nutzerrolle</label>
      <select class="form-control" formControlName="userRole" type="text">
        <option [value]="0">normaler Mitarbeiter</option>
        <option [disabled]="!(isAdmin() || isManagement())" [value]="1">Shop
          -Verwaltung</option>
        <option [disabled]="!isAdmin()" [value]="2">Admin</option>
      </select>
      <label class="form-label">Straße, Hausnr.</label>
      <input class="form-control" formControlName="street" type="text">
      <label class="form-label">Zusatz</label>
      <input class="form-control" formControlName="info" type="text">
      <label class="form-label">Plz.</label>
      <input class="form-control" formControlName="postCode" type="text">
      <label class="form-label">Ort</label>
      <input class="form-control" formControlName="city" type="text">
    </div>
  </form>
  <div *ngIf="user === undefined && editMode">
    <fa-icon [icon]="faSpinner"></fa-icon>
  </div>
  <div class="grid">
    <div class="update-info" *ngIf="showUpdateBox" @inOutAnimation>
      <fa-icon [icon]="checkIcon"></fa-icon>
      Nutzerdaten gespeichert!
    </div>
    <button *ngIf="editMode" (click)="saveUserInfo()" [disabled]="loading || form.invalid" class="btn btn-sm btn-success my-2">Nutzerdaten
      speichern
      <fa-icon *ngIf="loading" [icon]="faSpinner"></fa-icon>
    </button>
    <button *ngIf="!editMode" (click)="createUser()" [disabled]="loading || form.invalid"
            class="btn btn-sm btn-success my-2">Nutzer anlegen
      <fa-icon *ngIf="loading" [icon]="faSpinner"></fa-icon>
    </button>
  </div>

</div>
<div *ngIf="editMode">
  <h5>Bestellungen</h5>
  <div class="cartlist-wrapper my-2">
    <table class="table table-sm" *ngIf="shoppingCartList && shoppingCartList.length !== 0">
      <tr>
        <th>Erstellung</th>
        <th>letzte Änderung</th>
        <th>Punkte Beginn</th>
        <th>Punkte Ende</th>
        <th>Punkte Verbrauch</th>
        <th>Status</th>
        <th></th>
      </tr>
      <tr *ngFor="let sc of shoppingCartList" [ngClass]="{'selected': sc.id === shoppingCartSelected}">
        <td>{{ sc.created | date: 'dd.MM.y' }}</td>
        <td>{{ sc.updated | date: 'dd.MM.y' }}</td>
        <td>{{ sc.credits_available }}</td>
        <td>{{ sc.credits_remaining }}</td>
        <td>{{ sc.credits_available - sc.credits_remaining }}</td>
        <td>
          <span *ngIf="!sc.archived">aktuell</span>
          <span *ngIf="sc.archived">archiviert</span>
        </td>
        <td>
          <button class="btn btn-sm btn-success" style="background-color: green;" (click)="showCart(sc.id)">auswählen
          </button>
        </td>
      </tr>
    </table>
    <div *ngIf="!shoppingCartList || shoppingCartList.length === 0">
      keine Bestellungen vorhanden
    </div>
  </div>
  <h5>Warenkorb <span *ngIf="shoppingCart !== undefined"> vom {{ shoppingCart.created | date: 'dd.MM.y' }}
    <span *ngIf="shoppingCart.archived"> (archiviert)</span>
  <span *ngIf="!shoppingCart.archived"> (aktuell)</span>
</span></h5>
  <div *ngIf="shoppingCart != undefined && shoppingCartSelected != undefined">
    <div *ngIf="Object.keys(shoppingCart.cart).length === 0">Der Warenkorb ist leer!</div>
    <div *ngIf="Object.keys(shoppingCart.cart).length > 0" class="cart">
      <app-shopping-cart-item-view *ngFor="let item of shoppingCart.cart"
                                   [shoppingCartProduct]="item" [managementMode]="true"></app-shopping-cart-item-view>
    </div>
  </div>
  <div *ngIf="shoppingCart === undefined">
    <fa-icon [icon]="faSpinner"></fa-icon>
  </div>
</div>

